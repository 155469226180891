//
//  Toolbar
//  ---------------------------------------------

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar-products {
    @extend .abs-add-clearfix;
    margin-bottom: $indent__xl;
    text-align: center;

    @include max-screen($screen__xl) {
        margin-bottom: $indent__l;
    }
}

//
//  Amount
//  ---------------------------------------------

.toolbar-amount {
    float: left;
    @include lib-font-size(16px);
    font-weight: bold;
    line-height: 41px;
    margin-right: $indent__base;
    padding-right: $indent__base;
    border-right: 1px solid #e9e9e9;
    margin-bottom: 0;

    @include max-screen($screen__xl) {
        margin-right: $indent__s;
        padding-right: $indent__s;
    }
    @include max-screen($screen__m) {
        margin: 0;
        padding: 0;
        border: 0;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

//
//  Sorter
//  ---------------------------------------------

.sorter {
    float: left;
    padding: 7px 0;

    @include max-screen($screen__m) {
        float: right;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .sorter-label {
        @include lib-font-size(15px);
    }
    .sorter-options {
        width: auto;
        border-color: #c4c9cd;
        margin: 0 $indent__xs;
    }
    .sorter-action {
        display: inline-block;
        color: $primary__color;

        span {
            @extend .abs-visually-hidden;
        }
        &.sort-asc {
            &:before {
                @include fas();
                @include fa-icon();
                content: fa-content($fa-var-arrow-up);
            }
        }
        &.sort-desc {
            &:before {
                @include fas();
                @include fa-icon();
                content: fa-content($fa-var-arrow-down);
            }
        }
    }
}

//
//  Modes
//  ---------------------------------------------

.modes {
    float: right;

    @include max-screen($screen__l) {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }
    .modes-mode {
        color: $primary__color;
        border: 1px solid #d9ddde;
        float: left;
        padding: $indent__s;
        margin-left: $indent__s;
        font-weight: normal;
        border-radius: 1px;

        &:before {
            @include fas();
            @include fa-icon();
            @include lib-font-size(18px);
            content: fa-content($fa-var-th-large);
            margin-right: $indent__xs;
            position: relative;
            top: 1px;
        }
        &.mode-list {
            &:before {
                content: fa-content($fa-var-th-list);
            }
        }
        &:hover,
        &.active {
            text-decoration: none;
            border-color: #c4c9cd;
        }
    }
}

//
//  Limiter
//  ---------------------------------------------

.limiter {
    display: none;
    float: right;

    @include max-screen($screen__m) {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .label,
    .limiter-text {
        @include lib-font-size(15px);
    }
    .control {
        display: inline-block;

        .limiter-options {
            border-color: #c4c9cd;
            width: auto;
            margin: 0 $indent__xs;
        }
    }
}
