//
//  Social Login
//  ---------------------------------------------

.account-social-login {
    text-align: center;
    margin-top: $indent__xl;

    .block-content {
        font-size: 0;
    }
}

.white-popup {
    position: relative;
    background: #FFF;
    padding: 0;
    width: auto;
    max-width: 600px;
    margin: 0 auto;
}

#social-login-popup {
    overflow: hidden
}

#social-login-popup .block-container {
    width: 100%;
    display: inline-block;
}

#social-login-popup .social-login-title {
    padding: 10px 10px;
    background-color: #3399cc;
}

#social-login-popup .block-container .block {
    padding: 20px 25px;
    margin: 0;
}

.social-login-ajax-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6;
    background: #fbfaf6 url("../images/ajax-loader.gif") no-repeat center center;
}

#social-login-popup .block-container .block .block-title {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

#social-login-popup .fieldset {
    margin: 0 0 20px;
}

.account-social-login .actions-toolbar.social-btn {
    width: 256px;
    margin: 0 5px 10px;
}

#social-login-popup .actions-toolbar > .secondary .action.back {
    display: block;
}

.social-login-title h2 {
    margin: 0;
    padding-left: 40px;
    background: no-repeat 12px center;
    color: #ffffff;
}

.social-login-title .login-title {
    background-image: url("../images/login-title.png");
}

.social-login-title .create-account-title {
    background-image: url("../images/create-account-title.png");
}

.social-login-title .forgot-pass-title {
    background-image: url("../images/forgot-pass-title.png");
}

#social-login-popup .mfp-close {
    color: #ffffff;
}

#social-login-popup .message.error > *:first-child:before {
    top: 50%;
}

#social-login-popup .message.success > *:first-child:before {
    top: 50%;
}

/* Authentication Popup */
.social-login-authentication-popup {
    width: 100%;
}

.social-login-authentication-popup .block-content {
    display: block !important;
}

.social-login-authentication-popup .block-content .actions-toolbar {
    display: inline-block;
}

.social-login-authentication-popup .block-content .actions-toolbar .btn-social {
    height: 20px;
    padding-left: 19px;
}

/*Button Style*/
.actions-toolbar button {
    border-radius: 0;
}

.social-btn button {
    height: 33px;
    border: none;
}

.social-btn button span span {
    margin-left: 25px;
    color: #ffffff;
}

.social-login-authentication-channel .actions-toolbar.social-btn > .primary .action {
    width: 100%;
    margin: 0;
    min-width: 170px;
    text-align: left;
    border-radius: 5px;
}

.social-login-authentication-channel .actions-toolbar.social-btn > .primary .action:hover {
    opacity: 0.9;
}

.social-btn-actions-toolbar {
    display: inline-block;
}

.register-sociallogin {
    float: left;
}

#fb-customer, #gg-customer, #tw-customer, #li-customer, #ins-customer, #ya-customer, #git-customer, #vk-customer, #fo-customer, #live-customer, #am-customer {
    float: left;
}

#group-button {
    width: 48.8%; /*margin-left: 37px;*/ /*margin-top: 0px;*/
    float: right;
    clear: none;
}

#group-button .block.social-login-authentication-channel.col-mp.mp-5 {
    margin-bottom: 2px;
}

.mfp-zoom-in { /* start state */ /* animate in */ /* animate out */
}

.mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(0.8);
    opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-newspaper { /* start state */ /* animate in */ /* animate out */
}

.mfp-newspaper .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.5s;
    transform: scale(0) rotate(500deg);
}

.mfp-newspaper.mfp-bg {
    opacity: 0;
    transition: all 0.5s;
}

.mfp-newspaper.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1) rotate(0deg);
}

.mfp-newspaper.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-newspaper.mfp-removing .mfp-with-anim {
    transform: scale(0) rotate(500deg);
    opacity: 0;
}

.mfp-newspaper.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-move-horizontal { /* start state */ /* animate in */ /* animate out */
}

.mfp-move-horizontal .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s;
    transform: translateX(-50px);
}

.mfp-move-horizontal.mfp-bg {
    opacity: 0;
    transition: all 0.3s;
}

.mfp-move-horizontal.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: translateX(0);
}

.mfp-move-horizontal.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-move-horizontal.mfp-removing .mfp-with-anim {
    transform: translateX(50px);
    opacity: 0;
}

.mfp-move-horizontal.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-move-from-top { /* start state */ /* animate in */ /* animate out */
}

.mfp-move-from-top .mfp-content {
    vertical-align: top;
    margin-top: 50px;
}

.mfp-move-from-top .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s;
    transform: translateY(-100px);
}

.mfp-move-from-top.mfp-bg {
    opacity: 0;
    transition: all 0.2s;
}

.mfp-move-from-top.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: translateY(0);
}

.mfp-move-from-top.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-move-from-top.mfp-removing .mfp-with-anim {
    transform: translateY(-50px);
    opacity: 0;
}

.mfp-move-from-top.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-3d-unfold { /* start state */ /* animate in */ /* animate out */
}

.mfp-3d-unfold .mfp-content {
    perspective: 2000px;
}

.mfp-3d-unfold .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform-style: preserve-3d;
    transform: rotateY(-60deg);
}

.mfp-3d-unfold.mfp-bg {
    opacity: 0;
    transition: all 0.5s;
}

.mfp-3d-unfold.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: rotateY(0deg);
}

.mfp-3d-unfold.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-3d-unfold.mfp-removing .mfp-with-anim {
    transform: rotateY(60deg);
    opacity: 0;
}

.mfp-3d-unfold.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-zoom-out .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
}

.mfp-zoom-out.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
    transform: scale(1.3);
    opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
    opacity: 0;
}

.social-btn .btn-social {
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 6px 12px 6px 44px;
}

.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.btn-social > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.btn-social.btn-lg {
    padding-left: 61px
}

.btn-social.btn-lg > :first-child {
    line-height: 45px;
    width: 45px;
    font-size: 1.8em
}

.btn-social.btn-sm {
    padding-left: 38px
}

.btn-social.btn-sm > :first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em
}

.btn-social.btn-xs {
    padding-left: 30px
}

.btn-social.btn-xs > :first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em
}

.btn-social-icon {
    position: relative;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 34px;
    width: 34px;
    padding: 0;
}

.btn-social-icon > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.btn-social-icon.btn-lg {
    padding-left: 61px
}

.btn-social-icon.btn-lg > :first-child {
    line-height: 45px;
    width: 45px;
    font-size: 1.8em
}

.btn-social-icon.btn-sm {
    padding-left: 38px
}

.btn-social-icon.btn-sm > :first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em
}

.btn-social-icon.btn-xs {
    padding-left: 30px
}

.btn-social-icon.btn-xs > :first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em
}

.btn-social-icon > :first-child {
    border: none;
    text-align: center;
    width: 100%
}

.btn-social-icon.btn-lg {
    height: 45px;
    width: 45px;
    padding-left: 0;
    padding-right: 0
}

.btn-social-icon.btn-sm {
    height: 30px;
    width: 30px;
    padding-left: 0;
    padding-right: 0
}

.btn-social-icon.btn-xs {
    height: 22px;
    width: 22px;
    padding-left: 0;
    padding-right: 0
}

.btn-adn {
    color: #fff;
    background-color: #d87a68;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-adn:focus, .btn-adn.focus {
    color: #fff;
    background-color: #ce563f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-adn:hover {
    color: #fff;
    background-color: #ce563f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-adn:active, .btn-adn.active, .open > .dropdown-toggle.btn-adn {
    color: #fff;
    background-color: #ce563f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-adn:active:hover, .btn-adn.active:hover, .open > .dropdown-toggle.btn-adn:hover, .btn-adn:active:focus, .btn-adn.active:focus, .open > .dropdown-toggle.btn-adn:focus, .btn-adn:active.focus, .btn-adn.active.focus, .open > .dropdown-toggle.btn-adn.focus {
    color: #fff;
    background-color: #b94630;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-adn:active, .btn-adn.active, .open > .dropdown-toggle.btn-adn {
    background-image: none
}

.btn-adn.disabled:hover, .btn-adn[disabled]:hover, fieldset[disabled] .btn-adn:hover, .btn-adn.disabled:focus, .btn-adn[disabled]:focus, fieldset[disabled] .btn-adn:focus, .btn-adn.disabled.focus, .btn-adn[disabled].focus, fieldset[disabled] .btn-adn.focus {
    background-color: #d87a68;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-adn .badge {
    color: #d87a68;
    background-color: #fff
}

.btn-bitbucket {
    color: #fff;
    background-color: #205081;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-bitbucket:focus, .btn-bitbucket.focus {
    color: #fff;
    background-color: #163758;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-bitbucket:hover {
    color: #fff;
    background-color: #163758;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-bitbucket:active, .btn-bitbucket.active, .open > .dropdown-toggle.btn-bitbucket {
    color: #fff;
    background-color: #163758;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-bitbucket:active:hover, .btn-bitbucket.active:hover, .open > .dropdown-toggle.btn-bitbucket:hover, .btn-bitbucket:active:focus, .btn-bitbucket.active:focus, .open > .dropdown-toggle.btn-bitbucket:focus, .btn-bitbucket:active.focus, .btn-bitbucket.active.focus, .open > .dropdown-toggle.btn-bitbucket.focus {
    color: #fff;
    background-color: #0f253c;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-bitbucket:active, .btn-bitbucket.active, .open > .dropdown-toggle.btn-bitbucket {
    background-image: none
}

.btn-bitbucket.disabled:hover, .btn-bitbucket[disabled]:hover, fieldset[disabled] .btn-bitbucket:hover, .btn-bitbucket.disabled:focus, .btn-bitbucket[disabled]:focus, fieldset[disabled] .btn-bitbucket:focus, .btn-bitbucket.disabled.focus, .btn-bitbucket[disabled].focus, fieldset[disabled] .btn-bitbucket.focus {
    background-color: #205081;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-bitbucket .badge {
    color: #205081;
    background-color: #fff
}

.btn-dropbox {
    color: #fff;
    background-color: #1087dd;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-dropbox:focus, .btn-dropbox.focus {
    color: #fff;
    background-color: #0d6aad;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-dropbox:hover {
    color: #fff;
    background-color: #0d6aad;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-dropbox:active, .btn-dropbox.active, .open > .dropdown-toggle.btn-dropbox {
    color: #fff;
    background-color: #0d6aad;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-dropbox:active:hover, .btn-dropbox.active:hover, .open > .dropdown-toggle.btn-dropbox:hover, .btn-dropbox:active:focus, .btn-dropbox.active:focus, .open > .dropdown-toggle.btn-dropbox:focus, .btn-dropbox:active.focus, .btn-dropbox.active.focus, .open > .dropdown-toggle.btn-dropbox.focus {
    color: #fff;
    background-color: #0a568c;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-dropbox:active, .btn-dropbox.active, .open > .dropdown-toggle.btn-dropbox {
    background-image: none
}

.btn-dropbox.disabled:hover, .btn-dropbox[disabled]:hover, fieldset[disabled] .btn-dropbox:hover, .btn-dropbox.disabled:focus, .btn-dropbox[disabled]:focus, fieldset[disabled] .btn-dropbox:focus, .btn-dropbox.disabled.focus, .btn-dropbox[disabled].focus, fieldset[disabled] .btn-dropbox.focus {
    background-color: #1087dd;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-dropbox .badge {
    color: #1087dd;
    background-color: #fff
}

.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-facebook:focus, .btn-facebook.focus {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-facebook:hover {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-facebook:active, .btn-facebook.active, .open > .dropdown-toggle.btn-facebook {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-facebook:active:hover, .btn-facebook.active:hover, .open > .dropdown-toggle.btn-facebook:hover, .btn-facebook:active:focus, .btn-facebook.active:focus, .open > .dropdown-toggle.btn-facebook:focus, .btn-facebook:active.focus, .btn-facebook.active.focus, .open > .dropdown-toggle.btn-facebook.focus {
    color: #fff;
    background-color: #23345a;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-facebook:active, .btn-facebook.active, .open > .dropdown-toggle.btn-facebook {
    background-image: none
}

.btn-facebook.disabled:hover, .btn-facebook[disabled]:hover, fieldset[disabled] .btn-facebook:hover, .btn-facebook.disabled:focus, .btn-facebook[disabled]:focus, fieldset[disabled] .btn-facebook:focus, .btn-facebook.disabled.focus, .btn-facebook[disabled].focus, fieldset[disabled] .btn-facebook.focus {
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-facebook .badge {
    color: #3b5998;
    background-color: #fff
}

.btn-flickr {
    color: #fff;
    background-color: #ff0084;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-flickr:focus, .btn-flickr.focus {
    color: #fff;
    background-color: #cc006a;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-flickr:hover {
    color: #fff;
    background-color: #cc006a;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-flickr:active, .btn-flickr.active, .open > .dropdown-toggle.btn-flickr {
    color: #fff;
    background-color: #cc006a;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-flickr:active:hover, .btn-flickr.active:hover, .open > .dropdown-toggle.btn-flickr:hover, .btn-flickr:active:focus, .btn-flickr.active:focus, .open > .dropdown-toggle.btn-flickr:focus, .btn-flickr:active.focus, .btn-flickr.active.focus, .open > .dropdown-toggle.btn-flickr.focus {
    color: #fff;
    background-color: #a80057;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-flickr:active, .btn-flickr.active, .open > .dropdown-toggle.btn-flickr {
    background-image: none
}

.btn-flickr.disabled:hover, .btn-flickr[disabled]:hover, fieldset[disabled] .btn-flickr:hover, .btn-flickr.disabled:focus, .btn-flickr[disabled]:focus, fieldset[disabled] .btn-flickr:focus, .btn-flickr.disabled.focus, .btn-flickr[disabled].focus, fieldset[disabled] .btn-flickr.focus {
    background-color: #ff0084;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-flickr .badge {
    color: #ff0084;
    background-color: #fff
}

.btn-foursquare {
    color: #fff;
    background-color: #f94877;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-foursquare:focus, .btn-foursquare.focus {
    color: #fff;
    background-color: #f71752;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-foursquare:hover {
    color: #fff;
    background-color: #f71752;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-foursquare:active, .btn-foursquare.active, .open > .dropdown-toggle.btn-foursquare {
    color: #fff;
    background-color: #f71752;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-foursquare:active:hover, .btn-foursquare.active:hover, .open > .dropdown-toggle.btn-foursquare:hover, .btn-foursquare:active:focus, .btn-foursquare.active:focus, .open > .dropdown-toggle.btn-foursquare:focus, .btn-foursquare:active.focus, .btn-foursquare.active.focus, .open > .dropdown-toggle.btn-foursquare.focus {
    color: #fff;
    background-color: #e30742;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-foursquare:active, .btn-foursquare.active, .open > .dropdown-toggle.btn-foursquare {
    background-image: none
}

.btn-foursquare.disabled:hover, .btn-foursquare[disabled]:hover, fieldset[disabled] .btn-foursquare:hover, .btn-foursquare.disabled:focus, .btn-foursquare[disabled]:focus, fieldset[disabled] .btn-foursquare:focus, .btn-foursquare.disabled.focus, .btn-foursquare[disabled].focus, fieldset[disabled] .btn-foursquare.focus {
    background-color: #f94877;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-foursquare .badge {
    color: #f94877;
    background-color: #fff
}

.btn-github {
    color: #fff;
    background-color: #444;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:focus, .btn-github.focus {
    color: #fff;
    background-color: #2b2b2b;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:hover {
    color: #fff;
    background-color: #2b2b2b;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:active, .btn-github.active, .open > .dropdown-toggle.btn-github {
    color: #fff;
    background-color: #2b2b2b;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:active:hover, .btn-github.active:hover, .open > .dropdown-toggle.btn-github:hover, .btn-github:active:focus, .btn-github.active:focus, .open > .dropdown-toggle.btn-github:focus, .btn-github:active.focus, .btn-github.active.focus, .open > .dropdown-toggle.btn-github.focus {
    color: #fff;
    background-color: #191919;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:active, .btn-github.active, .open > .dropdown-toggle.btn-github {
    background-image: none
}

.btn-github.disabled:hover, .btn-github[disabled]:hover, fieldset[disabled] .btn-github:hover, .btn-github.disabled:focus, .btn-github[disabled]:focus, fieldset[disabled] .btn-github:focus, .btn-github.disabled.focus, .btn-github[disabled].focus, fieldset[disabled] .btn-github.focus {
    background-color: #444;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-github .badge {
    color: #444;
    background-color: #fff
}

.btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-google:focus, .btn-google.focus {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-google:hover {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-google:active, .btn-google.active, .open > .dropdown-toggle.btn-google {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-google:active:hover, .btn-google.active:hover, .open > .dropdown-toggle.btn-google:hover, .btn-google:active:focus, .btn-google.active:focus, .open > .dropdown-toggle.btn-google:focus, .btn-google:active.focus, .btn-google.active.focus, .open > .dropdown-toggle.btn-google.focus {
    color: #fff;
    background-color: #a32b1c;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-google:active, .btn-google.active, .open > .dropdown-toggle.btn-google {
    background-image: none
}

.btn-google.disabled:hover, .btn-google[disabled]:hover, fieldset[disabled] .btn-google:hover, .btn-google.disabled:focus, .btn-google[disabled]:focus, fieldset[disabled] .btn-google:focus, .btn-google.disabled.focus, .btn-google[disabled].focus, fieldset[disabled] .btn-google.focus {
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-google .badge {
    color: #dd4b39;
    background-color: #fff
}

.btn-google .fa-google {
    background: url("../images/g-logo.png") no-repeat;
}

.btn-google .fa-google:before {
    content: ""
}

.btn-instagram {
    color: #fff;
    background-color: #3f729b;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-instagram:focus, .btn-instagram.focus {
    color: #fff;
    background-color: #305777;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-instagram:hover {
    color: #fff;
    background-color: #305777;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-instagram:active, .btn-instagram.active, .open > .dropdown-toggle.btn-instagram {
    color: #fff;
    background-color: #305777;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-instagram:active:hover, .btn-instagram.active:hover, .open > .dropdown-toggle.btn-instagram:hover, .btn-instagram:active:focus, .btn-instagram.active:focus, .open > .dropdown-toggle.btn-instagram:focus, .btn-instagram:active.focus, .btn-instagram.active.focus, .open > .dropdown-toggle.btn-instagram.focus {
    color: #fff;
    background-color: #26455d;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-instagram:active, .btn-instagram.active, .open > .dropdown-toggle.btn-instagram {
    background-image: none
}

.btn-instagram.disabled:hover, .btn-instagram[disabled]:hover, fieldset[disabled] .btn-instagram:hover, .btn-instagram.disabled:focus, .btn-instagram[disabled]:focus, fieldset[disabled] .btn-instagram:focus, .btn-instagram.disabled.focus, .btn-instagram[disabled].focus, fieldset[disabled] .btn-instagram.focus {
    background-color: #3f729b;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-instagram .badge {
    color: #3f729b;
    background-color: #fff
}

.btn-linkedin {
    color: #fff;
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-linkedin:focus, .btn-linkedin.focus {
    color: #fff;
    background-color: #005983;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-linkedin:hover {
    color: #fff;
    background-color: #005983;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-linkedin:active, .btn-linkedin.active, .open > .dropdown-toggle.btn-linkedin {
    color: #fff;
    background-color: #005983;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-linkedin:active:hover, .btn-linkedin.active:hover, .open > .dropdown-toggle.btn-linkedin:hover, .btn-linkedin:active:focus, .btn-linkedin.active:focus, .open > .dropdown-toggle.btn-linkedin:focus, .btn-linkedin:active.focus, .btn-linkedin.active.focus, .open > .dropdown-toggle.btn-linkedin.focus {
    color: #fff;
    background-color: #00405f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-linkedin:active, .btn-linkedin.active, .open > .dropdown-toggle.btn-linkedin {
    background-image: none
}

.btn-linkedin.disabled:hover, .btn-linkedin[disabled]:hover, fieldset[disabled] .btn-linkedin:hover, .btn-linkedin.disabled:focus, .btn-linkedin[disabled]:focus, fieldset[disabled] .btn-linkedin:focus, .btn-linkedin.disabled.focus, .btn-linkedin[disabled].focus, fieldset[disabled] .btn-linkedin.focus {
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-linkedin .badge {
    color: #007bb6;
    background-color: #fff
}

.btn-microsoft {
    color: #fff;
    background-color: #2672ec;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-microsoft:focus, .btn-microsoft.focus {
    color: #fff;
    background-color: #125acd;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-microsoft:hover {
    color: #fff;
    background-color: #125acd;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-microsoft:active, .btn-microsoft.active, .open > .dropdown-toggle.btn-microsoft {
    color: #fff;
    background-color: #125acd;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-microsoft:active:hover, .btn-microsoft.active:hover, .open > .dropdown-toggle.btn-microsoft:hover, .btn-microsoft:active:focus, .btn-microsoft.active:focus, .open > .dropdown-toggle.btn-microsoft:focus, .btn-microsoft:active.focus, .btn-microsoft.active.focus, .open > .dropdown-toggle.btn-microsoft.focus {
    color: #fff;
    background-color: #0f4bac;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-microsoft:active, .btn-microsoft.active, .open > .dropdown-toggle.btn-microsoft {
    background-image: none
}

.btn-microsoft.disabled:hover, .btn-microsoft[disabled]:hover, fieldset[disabled] .btn-microsoft:hover, .btn-microsoft.disabled:focus, .btn-microsoft[disabled]:focus, fieldset[disabled] .btn-microsoft:focus, .btn-microsoft.disabled.focus, .btn-microsoft[disabled].focus, fieldset[disabled] .btn-microsoft.focus {
    background-color: #2672ec;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-microsoft .badge {
    color: #2672ec;
    background-color: #fff
}

.btn-odnoklassniki {
    color: #fff;
    background-color: #f4731c;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-odnoklassniki:focus, .btn-odnoklassniki.focus {
    color: #fff;
    background-color: #d35b0a;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-odnoklassniki:hover {
    color: #fff;
    background-color: #d35b0a;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-odnoklassniki:active, .btn-odnoklassniki.active, .open > .dropdown-toggle.btn-odnoklassniki {
    color: #fff;
    background-color: #d35b0a;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-odnoklassniki:active:hover, .btn-odnoklassniki.active:hover, .open > .dropdown-toggle.btn-odnoklassniki:hover, .btn-odnoklassniki:active:focus, .btn-odnoklassniki.active:focus, .open > .dropdown-toggle.btn-odnoklassniki:focus, .btn-odnoklassniki:active.focus, .btn-odnoklassniki.active.focus, .open > .dropdown-toggle.btn-odnoklassniki.focus {
    color: #fff;
    background-color: #b14c09;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-odnoklassniki:active, .btn-odnoklassniki.active, .open > .dropdown-toggle.btn-odnoklassniki {
    background-image: none
}

.btn-odnoklassniki.disabled:hover, .btn-odnoklassniki[disabled]:hover, fieldset[disabled] .btn-odnoklassniki:hover, .btn-odnoklassniki.disabled:focus, .btn-odnoklassniki[disabled]:focus, fieldset[disabled] .btn-odnoklassniki:focus, .btn-odnoklassniki.disabled.focus, .btn-odnoklassniki[disabled].focus, fieldset[disabled] .btn-odnoklassniki.focus {
    background-color: #f4731c;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-odnoklassniki .badge {
    color: #f4731c;
    background-color: #fff
}

.btn-openid {
    color: #fff;
    background-color: #f7931e;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-openid:focus, .btn-openid.focus {
    color: #fff;
    background-color: #da7908;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-openid:hover {
    color: #fff;
    background-color: #da7908;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-openid:active, .btn-openid.active, .open > .dropdown-toggle.btn-openid {
    color: #fff;
    background-color: #da7908;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-openid:active:hover, .btn-openid.active:hover, .open > .dropdown-toggle.btn-openid:hover, .btn-openid:active:focus, .btn-openid.active:focus, .open > .dropdown-toggle.btn-openid:focus, .btn-openid:active.focus, .btn-openid.active.focus, .open > .dropdown-toggle.btn-openid.focus {
    color: #fff;
    background-color: #b86607;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-openid:active, .btn-openid.active, .open > .dropdown-toggle.btn-openid {
    background-image: none
}

.btn-openid.disabled:hover, .btn-openid[disabled]:hover, fieldset[disabled] .btn-openid:hover, .btn-openid.disabled:focus, .btn-openid[disabled]:focus, fieldset[disabled] .btn-openid:focus, .btn-openid.disabled.focus, .btn-openid[disabled].focus, fieldset[disabled] .btn-openid.focus {
    background-color: #f7931e;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-openid .badge {
    color: #f7931e;
    background-color: #fff
}

.btn-pinterest {
    color: #fff;
    background-color: #cb2027;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-pinterest:focus, .btn-pinterest.focus {
    color: #fff;
    background-color: #9f191f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-pinterest:hover {
    color: #fff;
    background-color: #9f191f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-pinterest:active, .btn-pinterest.active, .open > .dropdown-toggle.btn-pinterest {
    color: #fff;
    background-color: #9f191f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-pinterest:active:hover, .btn-pinterest.active:hover, .open > .dropdown-toggle.btn-pinterest:hover, .btn-pinterest:active:focus, .btn-pinterest.active:focus, .open > .dropdown-toggle.btn-pinterest:focus, .btn-pinterest:active.focus, .btn-pinterest.active.focus, .open > .dropdown-toggle.btn-pinterest.focus {
    color: #fff;
    background-color: #801419;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-pinterest:active, .btn-pinterest.active, .open > .dropdown-toggle.btn-pinterest {
    background-image: none
}

.btn-pinterest.disabled:hover, .btn-pinterest[disabled]:hover, fieldset[disabled] .btn-pinterest:hover, .btn-pinterest.disabled:focus, .btn-pinterest[disabled]:focus, fieldset[disabled] .btn-pinterest:focus, .btn-pinterest.disabled.focus, .btn-pinterest[disabled].focus, fieldset[disabled] .btn-pinterest.focus {
    background-color: #cb2027;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-pinterest .badge {
    color: #cb2027;
    background-color: #fff
}

.btn-reddit {
    color: #000;
    background-color: #eff7ff;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-reddit:focus, .btn-reddit.focus {
    color: #000;
    background-color: #bcddff;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-reddit:hover {
    color: #000;
    background-color: #bcddff;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-reddit:active, .btn-reddit.active, .open > .dropdown-toggle.btn-reddit {
    color: #000;
    background-color: #bcddff;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-reddit:active:hover, .btn-reddit.active:hover, .open > .dropdown-toggle.btn-reddit:hover, .btn-reddit:active:focus, .btn-reddit.active:focus, .open > .dropdown-toggle.btn-reddit:focus, .btn-reddit:active.focus, .btn-reddit.active.focus, .open > .dropdown-toggle.btn-reddit.focus {
    color: #000;
    background-color: #98ccff;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-reddit:active, .btn-reddit.active, .open > .dropdown-toggle.btn-reddit {
    background-image: none
}

.btn-reddit.disabled:hover, .btn-reddit[disabled]:hover, fieldset[disabled] .btn-reddit:hover, .btn-reddit.disabled:focus, .btn-reddit[disabled]:focus, fieldset[disabled] .btn-reddit:focus, .btn-reddit.disabled.focus, .btn-reddit[disabled].focus, fieldset[disabled] .btn-reddit.focus {
    background-color: #eff7ff;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-reddit .badge {
    color: #eff7ff;
    background-color: #000
}

.btn-soundcloud {
    color: #fff;
    background-color: #f50;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-soundcloud:focus, .btn-soundcloud.focus {
    color: #fff;
    background-color: #c40;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-soundcloud:hover {
    color: #fff;
    background-color: #c40;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-soundcloud:active, .btn-soundcloud.active, .open > .dropdown-toggle.btn-soundcloud {
    color: #fff;
    background-color: #c40;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-soundcloud:active:hover, .btn-soundcloud.active:hover, .open > .dropdown-toggle.btn-soundcloud:hover, .btn-soundcloud:active:focus, .btn-soundcloud.active:focus, .open > .dropdown-toggle.btn-soundcloud:focus, .btn-soundcloud:active.focus, .btn-soundcloud.active.focus, .open > .dropdown-toggle.btn-soundcloud.focus {
    color: #fff;
    background-color: #a83800;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-soundcloud:active, .btn-soundcloud.active, .open > .dropdown-toggle.btn-soundcloud {
    background-image: none
}

.btn-soundcloud.disabled:hover, .btn-soundcloud[disabled]:hover, fieldset[disabled] .btn-soundcloud:hover, .btn-soundcloud.disabled:focus, .btn-soundcloud[disabled]:focus, fieldset[disabled] .btn-soundcloud:focus, .btn-soundcloud.disabled.focus, .btn-soundcloud[disabled].focus, fieldset[disabled] .btn-soundcloud.focus {
    background-color: #f50;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-soundcloud .badge {
    color: #f50;
    background-color: #fff
}

.btn-tumblr {
    color: #fff;
    background-color: #2c4762;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-tumblr:focus, .btn-tumblr.focus {
    color: #fff;
    background-color: #1c2d3f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-tumblr:hover {
    color: #fff;
    background-color: #1c2d3f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-tumblr:active, .btn-tumblr.active, .open > .dropdown-toggle.btn-tumblr {
    color: #fff;
    background-color: #1c2d3f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-tumblr:active:hover, .btn-tumblr.active:hover, .open > .dropdown-toggle.btn-tumblr:hover, .btn-tumblr:active:focus, .btn-tumblr.active:focus, .open > .dropdown-toggle.btn-tumblr:focus, .btn-tumblr:active.focus, .btn-tumblr.active.focus, .open > .dropdown-toggle.btn-tumblr.focus {
    color: #fff;
    background-color: #111c26;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-tumblr:active, .btn-tumblr.active, .open > .dropdown-toggle.btn-tumblr {
    background-image: none
}

.btn-tumblr.disabled:hover, .btn-tumblr[disabled]:hover, fieldset[disabled] .btn-tumblr:hover, .btn-tumblr.disabled:focus, .btn-tumblr[disabled]:focus, fieldset[disabled] .btn-tumblr:focus, .btn-tumblr.disabled.focus, .btn-tumblr[disabled].focus, fieldset[disabled] .btn-tumblr.focus {
    background-color: #2c4762;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-tumblr .badge {
    color: #2c4762;
    background-color: #fff
}

.btn-twitter {
    color: #fff;
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-twitter:focus, .btn-twitter.focus {
    color: #fff;
    background-color: #2795e9;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-twitter:hover {
    color: #fff;
    background-color: #2795e9;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-twitter:active, .btn-twitter.active, .open > .dropdown-toggle.btn-twitter {
    color: #fff;
    background-color: #2795e9;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-twitter:active:hover, .btn-twitter.active:hover, .open > .dropdown-toggle.btn-twitter:hover, .btn-twitter:active:focus, .btn-twitter.active:focus, .open > .dropdown-toggle.btn-twitter:focus, .btn-twitter:active.focus, .btn-twitter.active.focus, .open > .dropdown-toggle.btn-twitter.focus {
    color: #fff;
    background-color: #1583d7;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-twitter:active, .btn-twitter.active, .open > .dropdown-toggle.btn-twitter {
    background-image: none
}

.btn-twitter.disabled:hover, .btn-twitter[disabled]:hover, fieldset[disabled] .btn-twitter:hover, .btn-twitter.disabled:focus, .btn-twitter[disabled]:focus, fieldset[disabled] .btn-twitter:focus, .btn-twitter.disabled.focus, .btn-twitter[disabled].focus, fieldset[disabled] .btn-twitter.focus {
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-twitter .badge {
    color: #55acee;
    background-color: #fff
}

.btn-vimeo {
    color: #fff;
    background-color: #1ab7ea;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vimeo:focus, .btn-vimeo.focus {
    color: #fff;
    background-color: #1295bf;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vimeo:hover {
    color: #fff;
    background-color: #1295bf;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vimeo:active, .btn-vimeo.active, .open > .dropdown-toggle.btn-vimeo {
    color: #fff;
    background-color: #1295bf;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vimeo:active:hover, .btn-vimeo.active:hover, .open > .dropdown-toggle.btn-vimeo:hover, .btn-vimeo:active:focus, .btn-vimeo.active:focus, .open > .dropdown-toggle.btn-vimeo:focus, .btn-vimeo:active.focus, .btn-vimeo.active.focus, .open > .dropdown-toggle.btn-vimeo.focus {
    color: #fff;
    background-color: #0f7b9f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vimeo:active, .btn-vimeo.active, .open > .dropdown-toggle.btn-vimeo {
    background-image: none
}

.btn-vimeo.disabled:hover, .btn-vimeo[disabled]:hover, fieldset[disabled] .btn-vimeo:hover, .btn-vimeo.disabled:focus, .btn-vimeo[disabled]:focus, fieldset[disabled] .btn-vimeo:focus, .btn-vimeo.disabled.focus, .btn-vimeo[disabled].focus, fieldset[disabled] .btn-vimeo.focus {
    background-color: #1ab7ea;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vimeo .badge {
    color: #1ab7ea;
    background-color: #fff
}

.btn-vk {
    color: #fff;
    background-color: #587ea3;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vk:focus, .btn-vk.focus {
    color: #fff;
    background-color: #466482;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vk:hover {
    color: #fff;
    background-color: #466482;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vk:active, .btn-vk.active, .open > .dropdown-toggle.btn-vk {
    color: #fff;
    background-color: #466482;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vk:active:hover, .btn-vk.active:hover, .open > .dropdown-toggle.btn-vk:hover, .btn-vk:active:focus, .btn-vk.active:focus, .open > .dropdown-toggle.btn-vk:focus, .btn-vk:active.focus, .btn-vk.active.focus, .open > .dropdown-toggle.btn-vk.focus {
    color: #fff;
    background-color: #3a526b;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vk:active, .btn-vk.active, .open > .dropdown-toggle.btn-vk {
    background-image: none
}

.btn-vk.disabled:hover, .btn-vk[disabled]:hover, fieldset[disabled] .btn-vk:hover, .btn-vk.disabled:focus, .btn-vk[disabled]:focus, fieldset[disabled] .btn-vk:focus, .btn-vk.disabled.focus, .btn-vk[disabled].focus, fieldset[disabled] .btn-vk.focus {
    background-color: #587ea3;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-vk .badge {
    color: #587ea3;
    background-color: #fff
}

.btn-yahoo {
    color: #fff;
    background-color: #720e9e;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-yahoo:focus, .btn-yahoo.focus {
    color: #fff;
    background-color: #500a6f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-yahoo:hover {
    color: #fff;
    background-color: #500a6f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-yahoo:active, .btn-yahoo.active, .open > .dropdown-toggle.btn-yahoo {
    color: #fff;
    background-color: #500a6f;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-yahoo:active:hover, .btn-yahoo.active:hover, .open > .dropdown-toggle.btn-yahoo:hover, .btn-yahoo:active:focus, .btn-yahoo.active:focus, .open > .dropdown-toggle.btn-yahoo:focus, .btn-yahoo:active.focus, .btn-yahoo.active.focus, .open > .dropdown-toggle.btn-yahoo.focus {
    color: #fff;
    background-color: #39074e;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-yahoo:active, .btn-yahoo.active, .open > .dropdown-toggle.btn-yahoo {
    background-image: none
}

.btn-yahoo.disabled:hover, .btn-yahoo[disabled]:hover, fieldset[disabled] .btn-yahoo:hover, .btn-yahoo.disabled:focus, .btn-yahoo[disabled]:focus, fieldset[disabled] .btn-yahoo:focus, .btn-yahoo.disabled.focus, .btn-yahoo[disabled].focus, fieldset[disabled] .btn-yahoo.focus {
    background-color: #720e9e;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-yahoo .badge {
    color: #720e9e;
    background-color: #fff
}

.btn-amazon {
    color: #fff;
    background-color: #ff9900;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-amazon:focus, .btn-amazon.focus {
    color: #fff;
    background-color: #ffb425;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-amazon:hover {
    color: #fff;
    background-color: #ffb425;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-amazon:active, .btn-amazon.active, .open > .dropdown-toggle.btn-amazon {
    color: #fff;
    background-color: #ffb425;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-amazon:active:hover, .btn-amazon.active:hover, .open > .dropdown-toggle.btn-amazon:hover, .btn-amazon:active:focus, .btn-amazon.active:focus, .open > .dropdown-toggle.btn-amazon:focus, .btn-amazon:active.focus, .btn-amazon.active.focus, .open > .dropdown-toggle.btn-amazon.focus {
    color: #fff;
    background-color: #ffb425;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-amazon:active, .btn-amazon.active, .open > .dropdown-toggle.btn-amazon {
    background-image: none
}

.btn-amazon.disabled:hover, .btn-amazon[disabled]:hover, fieldset[disabled] .btn-amazon:hover, .btn-amazon.disabled:focus, .btn-amazon[disabled]:focus, fieldset[disabled] .btn-amazon:focus, .btn-amazon.disabled.focus, .btn-amazon[disabled].focus, fieldset[disabled] .btn-amazon.focus {
    background-color: #ff9900;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-amazon .badge {
    color: #ff9900;
    background-color: #fff
}

.mfp-wrap {
    overflow: scroll;
}

.btn-live {
    color: #fff;
    background-color: #0370c4;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-live:focus, .btn-live.focus {
    color: #fff;
    background-color: #0370c4;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-live:hover {
    color: #fff;
    background-color: #30548e;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-live:active, .btn-live.active, .open > .dropdown-toggle.btn-live {
    color: #fff;
    background-color: #0370c4;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-live:active:hover, .btn-live.active:hover, .open > .dropdown-toggle.btn-live:hover, .btn-live:active:focus, .btn-live.active:focus, .open > .dropdown-toggle.btn-live:focus, .btn-live:active.focus, .btn-live.active.focus, .open > .dropdown-toggle.btn-live.focus {
    color: #fff;
    background-color: #0370c4;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-live:active, .btn-live.active, .open > .dropdown-toggle.btn-live {
    background-image: none
}

.btn-live.disabled:hover, .btn-live[disabled]:hover, fieldset[disabled] .btn-live:hover, .btn-live.disabled:focus, .btn-live[disabled]:focus, fieldset[disabled] .btn-live:focus, .btn-live.disabled.focus, .btn-live[disabled].focus, fieldset[disabled] .btn-live.focus {
    background-color: #0370c4;
    border-color: rgba(0, 0, 0, 0.2)
}

.btn-live .badge {
    color: #0370c4;
    background-color: #fff
}

.btn-live .fa-live {
    background: url("../images/live-logo.png") no-repeat center;
}

.btn-live .fa-live:before {
    content: ""
}
