//
//  Grid System
//  ---------------------------------------------

// Generate semantic grid columns with these mixins.

// Centered container element
@mixin container-fixed($gutter: $grid-gutter-width) {
    @include abs-add-box-sizing();
    @include lib-clearfix();
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-left: floor(($gutter / 2));
    padding-right: ceil(($gutter / 2));
    max-width: $layout__max-width;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
    @include lib-clearfix();
    margin-left:  ceil(($gutter / -2));
    margin-right: floor(($gutter / -2));
}

// Generate the extra small columns
@mixin make-xxs-column($columns, $gutter: $grid-gutter-width) {
    @include abs-add-box-sizing();
    @include lib-clearfix();
    position: relative;
    float: left;
    width: percentage(($columns / $grid-columns));
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
}
@mixin make-xxs-column-offset($columns) {
    margin-left: percentage(($columns / $grid-columns));
}
@mixin make-xxs-column-push($columns) {
    left: percentage(($columns / $grid-columns));
}
@mixin make-xxs-column-pull($columns) {
    right: percentage(($columns / $grid-columns));
}

// Generate the xsmall columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
    @include min-screen($screen__xs) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-xs-column-offset($columns) {
    @include min-screen($screen__xs) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-xs-column-push($columns) {
    @include min-screen($screen__xs) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-xs-column-pull($columns) {
    @include min-screen($screen__xs) {
        right: percentage(($columns / $grid-columns));
    }
}

// Generate the small columns
@mixin make-s-column($columns, $gutter: $grid-gutter-width) {
    @include min-screen($screen__s) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-s-column-offset($columns) {
    @include min-screen($screen__s) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-s-column-push($columns) {
    @include min-screen($screen__s) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-s-column-pull($columns) {
    @include min-screen($screen__s) {
        right: percentage(($columns / $grid-columns));
    }
}

// Generate the medium columns
@mixin make-m-column($columns, $gutter: $grid-gutter-width) {
    @include min-screen($screen__m) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-m-column-offset($columns) {
    @include min-screen($screen__m) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-m-column-push($columns) {
    @include min-screen($screen__m) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-m-column-pull($columns) {
    @include min-screen($screen__m) {
        right: percentage(($columns / $grid-columns));
    }
}

// Generate the large columns
@mixin make-l-column($columns, $gutter: $grid-gutter-width) {
    @include min-screen($screen__l) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-l-column-offset($columns) {
    @include min-screen($screen__l) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-l-column-push($columns) {
    @include min-screen($screen__l) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-l-column-pull($columns) {
    @include min-screen($screen__l) {
        right: percentage(($columns / $grid-columns));
    }
}

// Generate the xlarge columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
    @include min-screen($screen__xl) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-xl-column-offset($columns) {
    @include min-screen($screen__xl) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-xl-column-push($columns) {
    @include min-screen($screen__xl) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-xl-column-pull($columns) {
    @include min-screen($screen__xl) {
        right: percentage(($columns / $grid-columns));
    }
}
