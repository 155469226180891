//
//  Cms
//  ---------------------------------------------

//
//  Back in Time Slider
//  ---------------------------------------------

#back-in-time {
    max-width: 270px;
    max-height: 421px;
    overflow: hidden;

    .slick-dots {
        bottom: 50px;
    }
}

//
//  Corporate
//  ---------------------------------------------

.cms-corporate,
.cms-corporate- {
    .breadcrumbs { display: none; }
    .splash {
    	width: 100%;
    	height: 500px;
    	background-size: cover;
    	text-align: center;
    	padding-top: 280px;

        @include max-screen($screen__l) {
        	height: 300px;
        	padding-top: 250px;
        }
        @include max-screen($screen__m) {
        	height: 200px;
        	padding-top: 150px;
        }
        @include max-screen($screen__xs) {
        	height: 150px;
        	padding-top: 100px;
        }
    }
    .splash h1 {
    	color: #fff;
    	font-weight: bold;
    	text-transform: uppercase;
    	font-size: 300%;
    }
    #maincontent {
    	max-width: 100%;
    	padding: 0;
    }
    .corporate-intro {
    	padding: 30px 20% 50px;
    	text-align: center;
    }
    .category-landing-linked-categories .name {
    	display: block;
    	min-height: 40px;
    }
}

//
//  What we do
//  ---------------------------------------------

.cms-what-we-do,
.cms-what-we-do- {
	.content hr { margin: 50px 0; }
	p.quote {
		padding: 50px;
		font-size: 1.4em;
		font-weight: bold;
		color: #33afa5;
	}
	p.highlight,
    h3.highlight {
		font-weight: bold;
		color: #33afa5;
	}
}

//
//  Corporate Social Responsibility
//  ---------------------------------------------

.cms-corporate-social-responsibility,
.cms-corporate-social-responsibility- {
	.policies {
		background-color: #e3e9ef;
		padding: 50px;
	}
	.content hr {
		margin: 50px 0;
	}
	.the-clink-charity-bg {
		margin-bottom: 30px;
		background-size:cover;
		width: 100%;
	}
	.the-clink-charity-bg .col-xxs-12 {
		padding: 30px;
		background-color: rgba(255, 255, 255, 0.9);
	}

	.where-come-from-bg {
		margin-top: 30px;
		margin-bottom: 30px;
		background-size: cover;
		width: 100%;
	}
	.where-come-from-bg .col-xxs-12 {
		padding: 30px;
		background-color: rgba(255, 255, 255, 0.9);
	}

	.support-the-clink-bg {
		margin-bottom: 30px;
		background-size:cover;
		width: 100%;
	}
	.support-the-clink-bg .col-xxs-12 {
		padding: 30px;
		background-color: rgba(255, 255, 255, 0.9);
	}
}

//
//  Gallery
//  ---------------------------------------------

.cms-gallery,
.cms-gallery- {
    .modal {
        display: none;
        position: fixed;
        z-index: 99999;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #b0b0b0;
    }
    .modal-content {
        position: relative;
        background-color: #b0b0b0;
        margin: auto;
        padding: 0;
        width: 33%;
        max-width: 1200px;

        @media only screen and (max-width: 600px)  {
            width: 90%;
        }
    }
    .close {
        color: white;
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 35px;
        font-weight: bold;
    }
    .close:hover,
    .close:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
    }
    .mySlides {
        display: none;
        text-align: center;
    }
    .mySlides img { width: 100%; }
    .mySlides span {
        display: block;
        padding-top: 15px;
        color: #fff;
    }
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 16px;
        margin-top: -50px;
        color: white;
        font-weight: bold;
        font-size: 20px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
    }
    .next {
        right: 0;
        border-radius: 3px 0 0 3px;
    }
    .prev:hover,
    .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .demo {
        opacity: 0.6;
    }
    .active,
    .demo:hover {
        opacity: 1;
    }
    img.hover-shadow {
        transition: 0.3s;
    }
    .gallery {
        margin-bottom: 10%;
        cursor: pointer;
    }
    .gallery:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}
