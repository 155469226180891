//
//  Prices
//  ---------------------------------------------

.price-box {
    .price-container {
        display: block;

        .price-including-tax,
        .price-excluding-tax {
            display: inline-block;
        }
        .price-including-tax {
            white-space: nowrap;
            margin-left: $indent__xs;

            &:before {
                content: "(";
            }
            &:after {
                content: 'inc VAT)';
                margin-left: 3px;
            }
        }
        .price-excluding-tax {
            white-space: nowrap;

            .price {
                @include lib-font-size(16px);
                font-weight: bold;

                .block-viewed-products-grid & {
                    @include lib-font-size(14px);
                }
            }

            &:after {
                @include lib-font-size(13px);
                content: 'Ex VAT';
                margin-left: $indent__xs;

                .block-viewed-products-grid & {
                    @include lib-font-size(12px);
                }
            }
        }
    }
    .old-price {
        @include lib-font-size(13px);
        display: block;
        height: 18px;

        .price-container {
            padding: 0;

            .price-wrapper {
                &.price-excluding-tax {
                    display: none;
                }
                &.price-including-tax {
                    margin: 0;

                    &:before {
                        display: none;
                    }
                    &:after {
                        content: 'inc VAT';
                    }
                }
                .price {
                    @include lib-font-size(13px);
                    font-weight: normal;

                    .block-viewed-products-grid & {
                        @include lib-font-size(12px);
                    }
                }
            }
        }
    }
    .normal-price {
        display: block;

        .price-label {
            display: none !important;
        }
    }
    .special-price {
        order: 2;
        display: block;

        .price-container {
            .price-label {
                color: $special__color;
                @include lib-font-size(16px);

                @include max-screen($screen__xs) {
                    display: none;
                }

                .block-viewed-products-grid & {
                    @include lib-font-size(14px);
                }
            }
            .price-wrapper {
                color: $special__color;
            }
        }
    }
    .price-from,
    .price-to {
        margin: 0;
    }
}
