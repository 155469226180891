//
//  Layout variables
//  _____________________________________________

$layout__max-width                              : 1380px;

//
//  Color variables
//  _____________________________________________

$primary__color                                 : #200041; // purple
$secondary__color                               : #33afa5; // seagreen
$special__color                                 : #f00c0c; // red
$border__color                                  : #d2d2d2;
$gray-lightest__color                           : #f6f7f9;

$active__color                                  : $secondary__color;

//
//  Typography variables
//  _____________________________________________

//  Font families
$font-family-name__base                         : 'Lato';

//  Colors
$text__color                                    : $primary__color;

//  Indent
$indent__m                                      : 15px;
$indent__xxl                                    : 60px;

//
//  Headings
//  ---------------------------------------------

$heading__font-weight__base                     : bold;
$heading__margin-top__base                      : 10px;
$h2__margin-top                                 : $heading__margin-top__base;
$h3__margin-top                                 : $heading__margin-top__base;

//
//  Focus
//  ---------------------------------------------

$focus__box-shadow                              : none;

//
//  Breadcrumbs variables
//  _____________________________________________

$breadcrumbs__container-margin                  : 0;
$breadcrumbs__font-size                         : 14px;
$breadcrumbs-icon__use                          : false;
$breadcrumbs-current__color                     : $primary__color;

//
//  Tabs variables
//  _____________________________________________

$tab-control__font-size                         : 15px;
$tab-control__font-weight                       : 400;
$tab-control__margin-right                      : 3px;
$tab-control__padding-top                       : 10px;
$tab-control__padding-right                     : 30px;
$tab-control__padding-bottom                    : 10px;
$tab-control__padding-left                      : 30px;
$tab-control__border-color                      : #d2d2d2;
$tab-control__border-width                      : 1px;

$tab-control__background-color                  : transparent;
$tab-control__color                             : $primary__color;

$tab-control__hover__background-color           : transparent;
$tab-control__hover__color                      : $primary__color;

$tab-control__active__background-color          : $primary__color;
$tab-control__active__color                     : #ffffff;

//
//  Tab content
//  ---------------------------------------------

$tab-content__background-color                  : #ffffff;
$tab-content__border-top-status                 : true;

$tab-content__padding-top                       : 30px;
$tab-content__padding-right                     : 30px;
$tab-content__padding-bottom                    : 30px;
$tab-content__padding-left                      : 30px;

//
//  Accordions
//  ---------------------------------------------

$accordion-control__height                      : auto;
$accordion-control__line-height                 : 1.428571429;
$accordion-control__font-size                   : 15px;
$accordion-control__margin-bottom               : 3px;
$accordion-control__padding-top                 : 10px;
$accordion-control__padding-right               : 30px;
$accordion-control__padding-bottom              : 10px;
$accordion-control__padding-left                : 30px;

$accordion-control__visited__color              : #ffffff;

$accordion-control__background-color            : #ffffff;
$accordion-control__color                       : $primary__color;

$accordion-control__hover__background-color     : #ffffff;
$accordion-control__hover__color                : $primary__color;

$accordion-control__active__background-color    : $primary__color;
$accordion-control__active__color               : #ffffff;

$accordion-content__background-color            : #ffffff;
$accordion-content__padding                     : 20px 30px;

//
//  Table variables
//  _____________________________________________

$table-cell__padding-horizontal                 : 30px;

//
//  Navigation variables
//  _____________________________________________

$navigation-level0-item__color                  : $primary__color;
$navigation-level0-item__active__border-color   : $secondary__color;
$navigation-level0-item__font-weight            : 500;

$navigation-desktop__font-weight                : 500;
$navigation-desktop-level0-item__margin         : 0;
$navigation-desktop-level0-item__padding        : 2px 0 0;
$navigation-desktop-level0-item__line-height    : 19px;
$navigation-desktop-level0-item__color          : $primary__color;
$navigation-desktop-level0-item__hover__color   : $primary__color;
$navigation-desktop-level0-item__hover__border-width            : 4;
$navigation-desktop-level0-item__active__color  : $primary__color;
$navigation-desktop-level0-item__active__border-width           : 4;

$submenu-desktop__font-weight                   : 500;
$submenu-desktop-item__active__border-color     : $secondary__color;

//
//  Messages variables
//  _____________________________________________

$message__margin                                : 0 0 20px;

//
//  Links
//  ---------------------------------------------

$link__color                                    : $secondary__color;

//
//  Buttons
//  ---------------------------------------------

$button__disabled__opacity                      : 0.1;

//  Font style
$button__padding                                : 12px 18px;
$button__font-weight                            : normal;

//
//  Form variables
//  _____________________________________________

$form-element-input__height                     : 35px;
$form-element-input__border-color               : #c2c2c2;
$form-element-input__color                      : $primary__color;

//
//  Fieldset
//  ---------------------------------------------

$form-fieldset-legend__margin                   : false;
$form-fieldset__margin                          : 0 0 15px;

//
//  Select
//  ---------------------------------------------

$select__padding                                : 7px 0 7px 10px;

//
//  Field
//  ---------------------------------------------

//  Form field label
$form-field-label__font-size                    : 14px;
$form-field-label__font-weight                  : 400;
$form-field-type-label-inline__padding          : 8px 0;
$form-field-type-label-inline__align            : left;
$form-field-label-asterisk__color               : #ffffff;
$form-field-label-asterisk__margin              : 0;

//  Form field label
$form-field-type-label-inline__width            : 33.33%;

//  Form field control
$form-field-type-control-inline__width          : 66.66%;

//
//  Header
//  _____________________________________________

$header-icons-color                             : $primary__color;
$header-icons-color-hover                       : $secondary__color;

//
//  Footer
//  _____________________________________________

$footer-text__color                             : #94acc6;
$footer-background-dark__color                  : #112e42;
$footer-background-light__color                 : #163f69;

//
//  Rating variables
//  _____________________________________________

$rating-icon__active__color                     : $primary__color;

//
//  Other variables
//  _____________________________________________

$checkout-shipping-item__active__border-color   : $secondary__color;
$account-nav-current-border-color               : $secondary__color;
