//
//  Typography
//  ---------------------------------------------

.items {
    @include lib-list-reset-styles();
}

cite {
    font-style: italic;
}

.help-block {
    display: block;
    margin-top: $indent__xs;
}

@include max-screen($screen__s) {
    table {
        & > tbody,
        & > tfoot,
        & > thead {
            & > tr {
                & > td,
                & > th {
                    padding: 5px 10px 5px 0;
                }
            }
        }
    }
}
