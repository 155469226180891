/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

// Variables (SCSS conversion)
$icons-pagebuilder-font-name: 'pagebuilder-font';
// $icons-pagebuilder-font-path: '#{$baseDir}Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons';

$icon-pagebuilder-caret-up-content: '\f104';
$icon-pagebuilder-caret-down-content: '\f101';
$icon-pagebuilder-caret-next-content: '\f102';
$icon-pagebuilder-caret-prev-content: '\f103';

// Common
@font-face {
    font-family: $icons-pagebuilder-font-name;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/pagebuilder-icons/pagebuilder-icons.eot');
    src: url('../fonts/pagebuilder-icons/pagebuilder-icons.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pagebuilder-icons/pagebuilder-icons.woff') format('woff'),
         url('../fonts/pagebuilder-icons/pagebuilder-icons.ttf') format('truetype');
}

.pagebuilder-icon {
    -webkit-font-smoothing: antialiased;
    font-family: $icons-pagebuilder-font-name;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
}

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: $icons-pagebuilder-font-name;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
}

.pagebuilder-icon-down:before {
    content: $icon-pagebuilder-caret-down-content;
}

.pagebuilder-icon-next:before {
    content: $icon-pagebuilder-caret-next-content;
}

.pagebuilder-icon-prev:before {
    content: $icon-pagebuilder-caret-prev-content;
}

.pagebuilder-icon-up:before {
    content: $icon-pagebuilder-caret-up-content;
}
