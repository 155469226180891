//
//  Product page
//  _____________________________________________

//
//  Product page imports
//  ---------------------------------------------

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';
@import 'module/category';
@import 'module/fonts';

//
//  Product page layout
//  ---------------------------------------------

.search.results {
    .block {
        display: none;
    }
}

.row {
    &.primary {
        margin-bottom: $indent__xl;

        @include min-screen($screen__l) {
            display: flex;
        }

        .product.media {
            @include make-xxs-column(12);
            @include make-l-column(4);
            @include make-xl-column(5);
            order: 1;
        }
        .product-info-description {
            @include make-xxs-column(12);
            @include make-s-column(6);
            @include make-m-column(7);
            @include make-l-column(4);
            order: 2;

            @include max-screen($screen__l) {
                margin-bottom: $indent__base;
            }
        }
        .product-info-main {
            @include make-xxs-column(12);
            @include make-s-column(6);
            @include make-m-column(5);
            @include make-l-column(4);
            @include make-xl-column(3);
            order: 3;
        }
    }
    &.secondary {
        @include min-screen($screen__l) {
            display: flex;
        }

        .product-related-wrapper {
            @include make-xxs-column(12);
            @include make-l-column(5);
            order: 1;
        }
        .product.info.detailed {
            @include make-xxs-column(12);
            @include make-l-column(7);
            order: 2;
        }
    }
}

//
//  Product media
//  ---------------------------------------------

.product-item-details {
    .product-image-container {
        display: block;
        max-width: 100%;
        width: auto !important;
    }
    .product-image-wrapper {
        display: block;
        height: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
    .product-image-photo {
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        margin: auto;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
}

//
//  Product media
//  ---------------------------------------------

.product.media {
    position: relative;
    margin-bottom: $indent__m;

    .gallery-placeholder {
        position: relative;
        padding-bottom: calc(100% + 102px);

        @include max-screen($screen__m) {
            padding-bottom: calc(100% + 50px);
        }
    }
    .gallery-placeholder__image {
        display: none;
    }
    .fotorama {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: visible;

        &:after {
            @include fa-icon();
            @include fas();
            content: fa-content($fa-var-search-plus);
            position: absolute;
            bottom: $indent__m;
            right: $indent__m;
            color: #cdd0d5;
            @include lib-font-size(23);
        }
    }
    .fotorama__img {
        cursor: zoom-in;
    }
    .fotorama__stage {
        border: 1px solid #eeeff0;
        box-sizing: border-box;
    }
    .fotorama__nav-wrap {
        margin-top: $indent__s;
    }
    .fotorama__caption {
        display: none;
    }
    .fotorama__thumb {
        box-sizing: border-box;
        border: 1px solid #eeeff0;
    }
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }
    .placeholder .photo.container {
        max-width: 100%;
    }
    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }
    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }
    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

//
//  Product description
//  ---------------------------------------------

.product-info-description {
    .page-title-wrapper {
        .page-title {
            @include lib-font-size(23px);
            font-weight: normal;
            margin-top: 0;
            margin-bottom: $indent__xs;
            line-height: $line-height__base;
        }
    }
    .product-info-stock-sku {
        margin-bottom: $indent__s;

        .product.attribute.altref {
            .type {
                display: none;
            }
            .value {
                &:before {
                    content: "Ref No. ";
                }
            }
        }
    }
    .ekomi-stars {
        @include lib-clearfix();
        margin-bottom: $indent__l;

        .ekomi-widget-container {
            float: left;
            width: 86px;
            height: 16px;
            background: url('https://sw-assets.ekomiapps.de/resources/stars_grey_86x16.png');
        }
        .ekomi-see-more {
            float: left;
            margin-left: $indent__s;
            line-height: 17px;
        }
    }
    .product.attribute.description {
        p,
        ul,
        table {
            @include lib-font-size(15px);
            margin-bottom: $indent__s;
        }
    }
    .sharer {
        @include lib-clearfix();
        margin-top: $indent__base;
        margin-bottom: $indent__base;

        .label {
            float: left;
            margin-right: $indent__s;

            span {
                vertical-align: 2px;
            }
            .fas {
                color: $secondary__color;
                @include lib-font-size(20px);
                margin-right: $indent__xs;
            }
        }
        a {
            @include lib-css(transition, color 0.2s, 1);
            float: left;
            margin-right: $indent__s;
            color: $secondary__color;
            @include lib-font-size(20px);

            &:hover {
                text-decoration: none;
                color: $primary__color;
            }
            .fab,
            .fas {
                display: block;
            }
            span {
                display: none;
            }
        }
    }
    &.alert {
        margin: $indent__s 0;
    }
    &-hotline {
        border-top: 1px solid #eeeff0;
        padding-top: $indent__base;
        background-color: $color-white;
        margin-top: $indent__base;

        a {
            @include lib-font-size(18px);
            margin-bottom: $indent__xs;

            .fas {
                margin-right: $indent__xs;
            }
        }
    }
}

//
//  Product main
//  ---------------------------------------------

.product-info-main {
    .product-info-price {
        background-color: $gray-lightest__color;
        padding: $indent__base $indent__base 0;

        .price-box {
            .price-container {
                .price-excluding-tax {
                    .price {
                        @include lib-font-size(20px);
                    }
                    &:after {
                        display: none;
                    }
                }
            }
            .old-price {
                .price-container {
                    .price-wrapper {
                        .price {
                            @include lib-font-size(14px);
                        }
                    }
                }
            }
            .special-price {
                .price-container {
                    .price-label {
                        @include lib-font-size(20px);
                    }
                }
            }
        }
    }
    .product.attribute {
        margin-bottom: $indent__m;

        .type {
            font-weight: normal;
        }
        .value {
            float: right;
        }
    }
    .product-add-form {
        @include lib-clearfix();
        background-color: $gray-lightest__color;
        padding: $indent__base $indent__base 0;

        .product-options-wrapper {
            .fieldset {
                & > .field:not(.configurable):not(.choice) {
                    margin-bottom: $indent__s;

                    &[data-option_name="linked"],
                    &[data-option_name="quantity"],
                    &[data-option_name="add-embroidery-flags-copy"],
                    &[data-option_name="add-embroidery-text-copy"],
                    &[data-option_name="flag-type-copy"] {
                        display: none !important;
                    }
                    &[data-option_name="i'm-over-18"] {
                        & > .label {
                            font-weight: bold;

                            &:after {
                                content: "*";
                                @include lib-font-size(14px);
                                color: red;
                                position: relative;
                                top: -3px;
                            }
                        }
                    }
                    & > .label {
                        width: auto;
                        padding: 4px 0;
                    }
                    & > #value {
                        display: none;
                    }
                    & > .control {
                        width: auto;
                        float: right;

                        .swatch-attribute-options {
                            margin: 0 -5px 0 0;

                            .mageworx-swatch-container {
                                padding: 5px 5px 0 0;

                                .mageworx-swatch-option {
                                    min-width: 20px;
                                }
                                & + .mageworx-swatch {
                                    display: block !important;
                                    visibility: hidden;
                                    height: 0;
                                    padding: 0;
                                    width: 0;
                                }
                            }
                        }
                        .choice {
                            @include lib-clearfix();

                            input {
                                display: none;

                                &[type="checkbox"] {
                                    float: left;
                                    display: block;
                                    visibility: hidden;
                                }
                                &[aria-invalid="true"] {
                                    & + .label {
                                        &:before {
                                            color: red;
                                        }
                                        &:after {
                                            content: "Required";
                                            @include lib-font-size(12px);
                                            position: absolute;
                                            right: calc(100% + 10px);
                                            color: $special__color;
                                            background-color: $color-white;
                                            padding: $indent__xs $indent__s;
                                            border: 1px solid $special__color;
                                            border-radius: 2px;
                                            box-shadow: 0px 0px 2px $special__color;
                                            font-weight: bold;
                                        }
                                    }
                                }
                                &:checked {
                                    & + .label {
                                        &:before {
                                            content: fa-content($fa-var-check-square);
                                            color: $primary__color;
                                        }
                                        &.loading {
                                            &:before {
                                                @extend .fa-spin;
                                                font-weight: 900;
                                                content: fa-content($fa-var-spinner);
                                            }
                                        }
                                    }
                                }
                            }
                            .label {
                                float: right;
                                @include lib-font-size(19px);
                                cursor: pointer;
                                position: relative;

                                span {
                                    display: none;
                                }
                                &:before {
                                    @include fa-icon();
                                    @include far();
                                    content: fa-content($fa-var-square);
                                    color: #c4c9cd;
                                }
                                &.loading {
                                    &:before {
                                        @extend .fa-spin;
                                        font-weight: 900;
                                        content: fa-content($fa-var-spinner);
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: $indent__m;
                    }
                }
            }
        }
        .box-tocart {
            .fieldset {
                @include lib-clearfix();

                & > .field {
                    text-align: left;
                }
            }
            .qty {
                @include lib-clearfix();
                position: relative;

                #qty {
                    float: left;
                }
                &-minus,
                &-plus {
                    position: absolute;
                    bottom: 0;
                    @include lib-css(transition, all 0.2s);
                    @include lib-font-size(12px);
                    float: left;
                    line-height: 33px;
                    width:37px;
                    text-align: center;
                    background-color: #e7e8ea;
                    color: #707070;
                    border: 1px solid #c4c9cd;

                    &:hover {
                        background-color: $color-white;
                        color: $primary__color;
                    }
                }
                &-minus {
                    right: 37px;
                }
                &-plus {
                    right: 0;
                }
            }
            .prices-tier {
                margin-bottom: $indent__base;

                & > li {
                    padding: $indent__xs $indent__s;

                    &:nth-of-type(2n+1) {
                        background-color: #e7e8ea;
                    }
                    .price-container {
                        display: none;
                    }
                    strong {
                        float: right;
                        color: #f13131;
                        font-weight: normal;
                    }
                }
            }
            .product-item-stock {
                margin: $indent__s 0 $indent__base;

                &.available {
                    color: #08ae00;
                }
                &.backorder {
                    color: #6f4400;
                }
                &.unavailable {
                    color: #f13131;
                }
            }
            .actions {
                position: relative;
                margin: 0 (-$indent__base);
                padding-right: 68px;

                .reset {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 68px;
                    height: 100%;
                    background-color: $primary__color;
                    color: $color-white;
                    text-align: center;
                    padding: 6px 0;
                    box-sizing: border-box;

                    .fas {
                        @include lib-css(transition, all 0.1s);
                        transform: rotate(0deg);
                    }
                    &:hover {
                        text-decoration: none;

                        .fas {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
    .product-social-links {
        margin-top: $indent__s;
    }
}

//
//  knife sharpening
//  ---------------------------------------------
#knife-sharpening{
    background: #f6f7f9;
    display: flex;
    color: #183153;
    line-height: 52px;
    align-items: center;
    padding: 0 10px;

    &:before{
        content:' ';
        display: inline-block;
        width:25px;
        height:23px;
        margin-right:5px;
        background:url(../images/knife.png);
    }
}
//
//  Product related block
//  ---------------------------------------------

.product-related-wrapper {
    .block.related,
    .block.upsell {
        .block-title.title {
            border-bottom: 1px solid $border__color;
            line-height: 41px;
            margin-bottom: $indent__l;

            strong {
                @include lib-font-size(16px);
                font-weight: normal;
            }
        }
        .product-items {
            display: none;
        }
    }
}

//
//  Product info tabs
//  ---------------------------------------------

.product.info.detailed {
    p {
        @include lib-font-size(15px);
    }
    ul {
        padding-left: $indent__base;
        @include lib-font-size(15px);
    }
    .product-icons {
        img {
            width: 65px;
            margin: 0 $indent__s $indent__s 0;
        }
    }
    table {
        margin-bottom: $indent__base;
        @include lib-font-size(15px);

        th {
            &:after {
                content: ":";
            }
        }
    }
    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__s,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
        );
    }
}

//
//  Product Links
//  ---------------------------------------------

.product-links {
    margin-bottom: $indent__l;

    .link {
        @include abs-add-clearfix;
        text-align: center;

        @include min-screen($screen__l) {
            background-color: $gray-lightest__color;
        }

        .row {
            @include min-screen($screen__l) {
                display: flex;
            }
        }
        .image {
            font-size: 0;

            img {
                display: inline-block;
            }
        }
        .content {
            align-self: center;

            @include min-screen($screen__l) {
                padding-right: $indent__m + $indent__l;
            }
            @include max-screen($screen__l) {
                padding: $indent__base;
            }

            h2 {
                @include lib-font-size(23px);
                font-weight: normal;
                margin-top: 0;

                @include max-screen($screen__xl) {
                    @include lib-font-size(20px);
                    margin-bottom: $indent__s;
                }
            }
            p {
                margin-bottom: $indent__base;

                @include max-screen($screen__xl) {
                    margin-bottom: $indent__s;
                }
            }
        }
    }
}

//
//  Size Guide Popup
//  ---------------------------------------------

.size-guide {
    display: block;
    text-decoration: underline;
    margin-bottom: $indent__m;
    text-align: left;
}

#size-guide {
    table {
        margin-bottom: $indent__base;

        tr {
            th {
                border-bottom: 2px solid $border__color;
                padding: $indent__s $indent__m;
            }
            td {
                border-bottom: 1px solid $border__color;
                padding: $indent__s $indent__m;
            }
            &:nth-of-type(2n) {
                td {
                    background-color: $gray-lightest__color;
                }
            }
        }
    }
}

//
//  Embroidery popup
//  ---------------------------------------------

#embroidery-popup {
    .loading {
        background-image: url('../images/ajax-loader.gif');
        background-repeat: no-repeat;
        background-position: left 2px;
        padding-left: $indent__base;
        margin-top: -1.5rem;
    }
}

//
//  Added to cart popup
//  ---------------------------------------------

.add-to-cart-popup,
.knife-popup,
.creditlimit-popup {
    .modal-inner-wrap {
        max-width: 560px;

        .modal-header,
        .modal-content,
        .modal-footer {
            padding: 3rem;
        }
        .modal-footer {
            padding-top: 0;
            font-size: 0;
            text-align: center;

            .action {
                display: inline-block;
                margin-bottom: 6px;
                margin-left: 3px;
            }
        }
    }
}
