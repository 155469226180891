//
//  Variables
//  _____________________________________________

$minicart__border-color       : $color-gray80 !default;
$minicart-qty__height         : 24px !default;

//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }
    .subtotal {
        @extend .abs-add-clearfix;
        @include lib-font-size(15px);
        margin-bottom: $indent__m;
        margin-top: $indent__s;

        .label {
            float: left;
        }
        .amount {
            float: right;
        }
    }
    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }
    .text {
        &.empty {
            text-align: center;
        }
    }
    .block-content {
        > .actions {
            margin-bottom: $indent__s;

            .primary {
                .view-basket {
                    text-align: center;
                    margin-bottom: $indent__s;
                }
                .checkout {
                    &:before {
                        @extend .fas;
                        @include fa-icon();
                        content: fa-content($fa-var-chevron-right);
                        float: right;
                        @include lib-font-size(16px);
                    }
                }
                div {
                    display: none;
                }
            }
        }
    }
    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

//
//  Minicart wrapper
//  ---------------------------------------------

header {
    .minicart-wrapper {
        float: right;

        @include lib-dropdown(
            $_toggle-selector                     : '.action.showcart',
            $_options-selector                    : '.block-minicart'
        );
        margin-left: ($indent__xl * 3);
        margin-top: $indent__xl;

        @include max-screen($screen__xl) {
            margin-left: $indent__xl;
        }
        @include max-screen($screen__l) {
            margin-top: $indent__s;
            float: right;
        }
        @include max-screen($screen__m) {
            margin: 0;
        }

        .block-minicart {
            padding: $indent__base;
            right: 0;
            z-index: 101;
            box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.41);
            margin-top: 0;
            border-color: #c6c6c6;
            text-align: left;

            @include max-screen($screen__m) {
                width: 290px;
                right: 0;
                top: 31px;
            }
            @include min-screen($screen__m) {
                width: 400px;
            }

            &:before,
            &:after {
                display: none;
            }
            .block-title {
                padding-bottom: $indent__s;
                border-bottom: 1px solid #eeeeee;

                .text {
                    @include lib-font-size(15px);
                    font-weight: normal;
                }
            }
        }
        .product {
            .actions {
                float: left;
            }
        }
        .action {
            &.close {
                @include lib-button-icon(
                    $icon-remove,
                    $_icon-font-size        : 32px,
                    $_icon-font-line-height : 32px,
                    $_icon-font-text-hide   : true
                );
                @include lib-button-reset();
                height: 40px;
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
            }

            &.showcart {
                white-space: nowrap;
                background-color: $gray-lightest__color;
                @include lib-font-size(16px);
                color: $primary__color;
                padding: $indent__s $indent__m;
                min-width: 203px;
                box-sizing: border-box;
                font-size: 0;

                @include max-screen($screen__m) {
                    min-width: auto;
                    background: none;
                    padding: 0;
                }

                &:before {
                    @extend .fas;
                    @include fa-icon();
                    content: fa-content($fa-var-shopping-basket);
                    @include lib-font-size(17px);
                    margin-right: $indent__xs;

                    @include max-screen($screen__m) {
                        @include lib-font-size(30px);
                    }
                }
                &:after {
                    display: none !important;
                }
                .text {
                    @extend .abs-visually-hidden;
                }
                .counter {
                    @include lib-font-size(16px);

                    @include max-screen($screen__m) {
                        display: none;
                    }

                    &.number {
                        &:after {
                            content: " items";
                        }
                    }
                    &.subtotal {
                        float: right;
                        margin-left: $indent__s;
                    }
                    .loader {
                        > img {
                            @include lib-css(max-width, $minicart-qty__height);
                        }
                    }
                }
            }
            &.checkout-top {
                float: right;

                @include max-screen($screen__m) {
                    display: none;
                }
            }
        }
        .minicart-widgets {
            margin-top: 15px;
        }
        .minicart-items {
            @include lib-list-reset-styles();

            .product-item {
                padding: $indent__m 0;
                border-top: 1px solid #eeeeee;

                &:first-child {
                    border: 0;
                }
                > .product {
                    @extend .abs-add-clearfix;
                }
                &:hover {
                    background: none;
                }
            }
            .product-image-wrapper {
                @extend .abs-reset-image-wrapper;
            }
            .price-minicart {
                margin-bottom: $indent__xs;
            }
            .product {
                > .product-item-photo,
                > .product-image-container {
                    float: left;
                }
                .toggle {
                    margin-bottom: $indent__xs;
                    @include lib-font-size(13);
                    cursor: pointer;
                    position: relative;

                    &:after {
                        @extend .fas;
                        @include fa-icon;
                        content: fa-content($fa-var-chevron-down);
                        margin-left: $indent__xs;
                    }
                }
                &.active {
                    .toggle {
                        &:after {
                            content: fa-content($fa-var-chevron-up);
                        }
                    }
                }
                .content {
                    @extend .abs-add-clearfix;
                }
            }
            .product-item-name {
                display: block;
                font-weight: $font-weight__regular;
                @include lib-font-size(15);
                margin: 0 0 $indent__xs;

                a {
                    @include lib-css(color, $primary__color);
                }
            }
            .product.options {
                .tooltip.toggle {
                    @include lib-icon-font(
                        $icon-down,
                        $_icon-font-size        : 28px,
                        $_icon-font-line-height : 28px,
                        $_icon-font-text-hide   : true,
                        $_icon-font-margin      : -3px 0 0 7px,
                        $_icon-font-position    : after
                    );

                    .details {
                        display: none;
                    }
                }
            }
            .product-item-details {
                padding-left: 88px;
            }
            .product-item-pricing {
                margin-top: $indent__xs;
                margin-bottom: $indent__s;

                .price {
                    @include lib-font-size(15);
                }
            }
            .details-qty {
                float: right;

                .label {
                    display: none;
                }
                .item-qty {
                    @include lib-font-size(11);
                    text-align: center;
                    width: 33px;
                    height: 23px;
                    border-color: #e4e4e4;
                    border-radius: 0;
                    color: #2f2f2f;
                }
            }
            .subtitle {
                display: none;
            }
            .product.options.list {
                @include lib-font-size(13);
                margin: 0;

                dt,
                dd {
                    margin: 1px 0;
                }

                @include min-screen($screen__m) {
                    dt,
                    dd {
                        float: left;
                    }
                    dt {
                        width: 60%;

                        &:after {
                            content: ":";
                        }
                    }
                    dd {
                        width: 40%;
                    }
                }
            }
            .action {
                &.edit {
                    display: none;
                }
                &.delete {
                    color: #9d9b9c;
                }
            }
        }
    }
    .minicart-items-wrapper {
        overflow-x: auto;
        border-bottom: 1px solid #eeeeee;
    }
}
