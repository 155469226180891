//
//  Buttons
//  ---------------------------------------------

button.action,
a.action {
    @include lib-css(transition, all 0.2s);
    white-space: nowrap;
}

//
//  Default Button
//  ---------------------------------------------

$button__color                                  : $primary__color;
$button__background                             : $gray-lightest__color;
$button__border                                 : 1px solid $border__color;

$button__hover__color                           : $color-white;
$button__hover__background                      : $primary__color;
$button__hover__border                          : 1px solid $primary__color;

$button__active__color                          : $button__hover__color;
$button__active__background                     : $button__hover__background;
$button__active__border                         : $button__hover__border;

//
//  Default Button
//  ---------------------------------------------

.action.default {
    @include lib-button(
        $_button-color                          : $button__color,
        $_button-background                     : $button__background,
        $_button-border                         : $button__border,
        $_button-color-hover                    : $button__hover__color,
        $_button-background-hover               : $button__hover__background,
        $_button-border-hover                   : $button__hover__border,
        $_button-color-active                   : $button__active__color,
        $_button-background-active              : $button__active__background,
        $_button-border-active                  : $button__active__border
    );
}

a.action.default {
    @include lib-link-as-button();
}

//
//  Primary Button Variables
//  ---------------------------------------------

$button-primary__color                          : $color-white;
$button-primary__background                     : $primary__color;
$button-primary__border                         : 1px solid $primary__color;

$button-primary__hover__color                   : $button-primary__color;
$button-primary__hover__background              : $secondary__color;
$button-primary__hover__border                  : 1px solid $secondary__color;

$button-primary__active__color                  : $button-primary__hover__color;
$button-primary__active__background             : $button-primary__hover__background;
$button-primary__active__border                 : $button-primary__hover__border;

//
//  Primary Button
//  ---------------------------------------------

.action.primary {
    @include lib-button(
        $_button-color                         : $button-primary__color,
        $_button-background                    : $button-primary__background,
        $_button-border                        : $button-primary__border,
        $_button-color-hover                   : $button-primary__hover__color,
        $_button-background-hover              : $button-primary__hover__background,
        $_button-border-hover                  : $button-primary__hover__border,
        $_button-color-active                  : $button-primary__active__color,
        $_button-background-active             : $button-primary__active__background,
        $_button-border-active                 : $button-primary__active__border
    );
}

a.action.primary {
    @include lib-link-as-button();
}

//
//  Secondary Button Variables
//  ---------------------------------------------

$button-secondary__color                        : $color-white;
$button-secondary__background                   : $secondary__color;
$button-secondary__border                       : 1px solid $secondary__color;

$button-secondary__hover__color                 : $button-secondary__color;
$button-secondary__hover__background            : $primary__color;
$button-secondary__hover__border                : 1px solid $primary__color;

$button-secondary__active__color                : $button-secondary__hover__color;
$button-secondary__active__background           : $button-secondary__hover__background;
$button-secondary__active__border               : $button-secondary__hover__border;

//
//  Secondary Button
//  ---------------------------------------------

.action.secondary {
    @include lib-button(
        $_button-color                          : $button-secondary__color,
        $_button-background                     : $button-secondary__background,
        $_button-border                         : $button-secondary__border,
        $_button-color-hover                    : $button-secondary__hover__color,
        $_button-background-hover               : $button-secondary__hover__background,
        $_button-border-hover                   : $button-secondary__hover__border,
        $_button-color-active                   : $button-secondary__active__color,
        $_button-background-active              : $button-secondary__active__background,
        $_button-border-active                  : $button-secondary__active__border
    );
}

a.action.secondary {
    @include lib-link-as-button();
}

//
//  White Button Variables
//  ---------------------------------------------

$button-white__color                            : $primary__color;
$button-white__background                       : $color-white;
$button-white__border                           : 1px solid $color-white;

$button-white__hover__color                     : $color-white;
$button-white__hover__background                : $primary__color;
$button-white__hover__border                    : 1px solid $primary__color;

$button-white__active__color                    : $button-white__hover__color;
$button-white__active__background               : $button-white__hover__background;
$button-white__active__border                   : $button-white__hover__border;

//
//  White Button
//  ---------------------------------------------

.action.white {
    @include lib-button(
        $_button-color                          : $button-white__color,
        $_button-background                     : $button-white__background,
        $_button-border                         : $button-white__border,
        $_button-color-hover                    : $button-white__hover__color,
        $_button-background-hover               : $button-white__hover__background,
        $_button-border-hover                   : $button-white__hover__border,
        $_button-color-active                   : $button-white__active__color,
        $_button-background-active              : $button-white__active__background,
        $_button-border-active                  : $button-white__active__border
    );
    padding-left: 50px;
    padding-right: 50px;
    text-transform: uppercase;
}

a.action.white {
    @include lib-link-as-button();
}

//
//  Large
//  ---------------------------------------------

.action.large {
    @include lib-font-size(18px);
    @include lib-css(padding, 16px 24px);
    @include lib-css(font-weight, bold);
}

//
//  Medium
//  ---------------------------------------------

.action.medium {
    @include lib-font-size(16px);
    @include lib-css(padding, 14px 21px);
    @include lib-css(font-weight, bold);
}

//
//  Small
//  ---------------------------------------------

.action.small {
    @include lib-font-size(12px);
    @include lib-css(padding, 10px 15px);
    text-transform: uppercase;
}

//
//  Fullwidth
//  ---------------------------------------------

.action.fullwidth {
    width: 100%;
}

//
//  Other
//  ---------------------------------------------

.action.right {
    float: right;
}

.action.left {
    float: left;
}

.action.white {
    padding-left: 35px;
    padding-right: 35px;
    text-transform: uppercase;

    &.medium {
        padding-left: 50px;
        padding-right: 50px;
    }
}
