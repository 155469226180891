//
//  Amasty\Checkout
//  ---------------------------------------------

$icon-close-base64: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTS0xLTFoMTJ2MTJILTF6Ii8+PHBhdGggZmlsbD0iI2I1YjViNSIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkPSJNNiA1bDQtNC0xLTEtNCA0LTQtNC0xIDEgNCA0LTQgNCAxIDEgNC00IDQgNCAxLTEtNC00eiIgc3Ryb2tlPSJudWxsIi8+PC9zdmc+);

//
//  Common
//  ---------------------------------------------

.checkout-index-index {
    #checkout.am-checkout {
        margin-top: $indent__s;
        background: #ffffff;
        padding: 0;

        .checkout-header {
            display: none;
        }
        .authentication-wrapper {
            display: none;
        }
        .opc-estimated-wrapper {
            display: none;
        }
        .opc-wrapper {
            float: none;
            margin: 0;
            padding: 0;
            @include make-row();
            width: auto;

            .checkout-column {
                padding: 0 $indent__m;

                .checkout-block {
                    padding: $indent__l $indent__base $indent__l $indent__l;
                    background: white;
                    border: 1px solid #eeeff0;
                    margin: 0 0 $indent__l;
                    box-shadow: none;

                    .step-title {
                        @include lib-font-size(23px);
                        font-weight: normal;
                        border: 0;
                        padding: 0;
                        margin-bottom: $indent__base;
                    }
                }
                &:last-child {
                    .checkout-block {
                        border: 0;
                        padding: 0;
                        background: 0;
                    }
                }
            }
            &.layout-2-columns {
                .checkout-column {
                    @include make-xxs-column(12);
                    @include make-m-column(6);
                }
            }
            &.layout-3-columns {
                .checkout-column {
                    @include make-xxs-column(12);
                    @include make-m-column(4);
                }
            }
            &.layout-3-columns .shipping-address-item {
                &:nth-child(3n + 1):before {
                    display: block;
                }
                &:nth-child(2n + 1):before {
                    display: none;
                }
            }
            &.layout-3-columns .shipping-address-item,
            &.layout-2-3-columns .shipping-address-item {
                width: 50%;

                @include max-screen($screen__s) {
                    width: 100%;
                }

                &:only-child {
                    width: 100%;
                }
            }
            &.layout-3-columns .table-checkout-shipping-method,
            &.layout-2-3-columns .table-checkout-shipping-method {
                width: 100%;
                min-width: 0;
            }
            .payment-method-note {
                font-weight: bold;
            }
            &:before {
                display: none;
            }
            .step-content {
                margin: 0;
            }
            .shipping-address {
                margin-bottom: 0;
            }
            #co-payment-form {
                .discount-code {
                    display: none;
                }
                .rewards-add {
                    display: none;
                }
                .gift-code {
                    display: none;
                }
            }
            .discount-code .payment-option-title {
                border: none;
            }
            .checkout-agreement {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-prefix-flex-wrap(wrap);

                &:before {
                    display: none;
                }
            }
            .checkout-agreement label {
                @include lib-css(flex, 1 , 1);
            }
            .checkout-agreement div.mage-error {
                @include lib-vendor-prefix-flex-basis(100%);
            }
            .checkout-agreement .action-show {
                text-align: left;
            }
            .payment-option-content .action-apply {
                margin-right: 0;
            }
            .payment-method-content .primary {
                padding-right: 0;
            }
            .checkout-block > li {
                list-style-type: none;
            }
            .fieldset {
                .field {
                    .note {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
            fieldset {
                .control {
                    .field {
                        .label {
                            display: none;
                        }
                    }
                }
            }
            .field.am-comment {
                margin-bottom: 10px;
            }
            .form-shipping-address,
            .form-billing-address {
                .control {
                    input,
                    select {
                        margin-right: 10px;
                        width: calc(100% - 36px);
                    }
                }
            }
            .label {
                word-wrap: break-word;
            }
            .item-options {
                .label {
                    float: left;
                    clear: left;
                    margin-bottom: $indent__s;

                    &:after {
                        content: ":";
                    }
                }
                .values {
                    float: right;
                    text-align: right;
                }
            }
            .payment-method-title {
                .cc-radio {
                    margin-right: $indent__s;
                }
            }
            .checkout-billing-address {
                max-width: 500px;

                .billing-address-same-as-shipping-block {
                    margin: 5px 0 10px;
                }
                .actions-toolbar {
                    margin-top: 15px;
                }
                .field-select-billing .label {
                    display: none;
                }
            }
        }
        .am-submit-summary #checkout-payment-method-load {
            .actions-toolbar {
                display: none;
            }
            .payment-method-billing-address .actions-toolbar {
                display: block;
            }
        }
        .am-submit-fixed {
            #checkout-payment-method-load .actions-toolbar {
                display: block;
                position: fixed;
                z-index: 999;
                width: $layout__max-width - ($layout-indent__width * 2);
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                background-color: #eaeaea;
                left: 50%;
                transform: translate(-50%);
            }
            #checkout-payment-method-load .actions-toolbar > * {
                float: none;
            }
            &.-top #checkout-payment-method-load .actions-toolbar {
                bottom: auto;
                top: 0;
            }
            &.-bottom #checkout-payment-method-load .actions-toolbar {
                bottom: 0;
            }
        }
        .opc-block-summary {
            background-color: $gray-lightest__color;
            padding: $indent__l;
            margin: 0 0 $indent__l;

            .view-basket {
                margin-bottom: $indent__s;
            }
            .minicart-items-wrapper {
                margin: 0;
            }
        }
        .additional-options {
            margin: 24px 7px 12px;

            & .checkout-agreements {
                & .action-show {
                    line-height: 1.42857143;
                    padding: 0;
                    color: #006bb4;
                    text-decoration: none;
                    background: none;
                    border: 0;
                    display: inline;
                    font-weight: 400;
                    border-radius: 0;
                }
                & .action-show:not(:focus) {
                    box-shadow: none;
                }
            }
            .field {
                margin-top: 7px;
            }
        }
        .am-gift-message {
            .edit-link {
                display: none;
                color: #1979c3;
                cursor: pointer;
            }
            &.checked .edit-link {
                display: inline;
            }
        }
        .amcheckout-delivery-date .field {
            float: left;
            padding-top: 4px;
            width: 100%;
            box-sizing: border-box;

            &.comment {
                width:100%;
            }
            &.datepicker,
            &.timepicker {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-box-align(center);
                @include lib-css(justify-content, space-between, 1);
            }
            &.datepicker .control,
            &.timepicker .control {
                @include lib-css(flex, none, 1);
                width: 60%;
            }
            &.datepicker .control .ui-datepicker-trigger {
                margin-left: 7px;
            }
            .label {
                display: block;
                margin: 12px 0;
            }
        }
        .amcheckout-delivery-date .clearer {
            clear: both;
        }
        .amcheckout-delivery-date .field._required > .label:after,
        .additional-options .field._required .label:after {
            margin: 0 0 0 5px;
            font-size: 1.2rem;
            content: '*';
            color: #e02b27;
        }
        .opc-block-summary .minicart-items {
            input.qty {
                @include lib-font-size(11);
                text-align: center;
                width: 33px;
                height: 23px;
                border-color: #e4e4e4;
                border-radius: 0;
                color: #2f2f2f;
            }
            button {
                margin-top: 12px;
                float: right;
            }
            .delete {
                float: right;
                padding: 3px;
                width: 7px;
                height: 7px;
                border: 1px #b6b6b6 solid;
                @include lib-css(background, $icon-close-base64 no-repeat center);
                background-size: 7px;
                cursor: pointer;
            }
        }

        //Override PostNL styles - Begin
        #opc-shipping_method #checkout-shipping-method-load .table-checkout-shipping-method {
            max-width: 600px;

            .col-price {
                min-width: unset;
            }
        }

        //Override PostNL styles - End
        .modal-popup:not(.agreements-modal):not(.popup-authentication) .modal-inner-wrap {
            right: 0;
            left: 0;
            width: 500px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.checkout-payment-method .payment-methods {
    margin: 0;

    .actions-toolbar .primary {
        padding: 14px 17px;
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}
.checkout-payment-method.submit .payment-methods {
    margin: 0;
}
.fieldset.address {
    margin: 0 -7px;

    & > .field {
        display: inline-block;
        padding: 0 7px;
        width: 100%;
        vertical-align: top;
        box-sizing: border-box;
    }
}

// Success Page
.checkout-success .success-messages {
    margin-bottom: 40px;
    font-size: 1.8rem;
}

.account .column.main .order-details-items {
    margin-bottom: 0;

    .order-items .data.table > tbody > tr:nth-child(even) > td {
        background: none;
    }
}

.page-main .block.block-order-details {
    margin-bottom: 25px;

    .delivery {
        float: right;
        font-weight: bold;
    }
    .delivery .delivery-field {
        float: left;
        margin-left: 12px;
    }
    .order-date {
        padding-bottom: 25px;
        border-bottom: 1px solid #c6c6c6;
        font-size: 1.6rem;
    }
}

.page-main .block.block-order-details-comments {
    margin-bottom: 35px;
}

.page-main .block.block-order-details-view {
    margin-bottom: 10px;

    &:nth-child(2) .block-title {
        display: none;
    }
}

#registration {
    position: relative;
    margin: 20px 0 25px;
    padding: 0 0 0 12px;
    width: 600px;
    border-left: 3px solid #f58c12;
    br {
        display: none;
    }
    form {
        position: absolute;
        top: 50%;
        right: 0;
        @include lib-css(transform, translateY(-50%), 1);
    }
}

//
//  Tablet
//  ---------------------------------------------

@include min-screen($screen__s) {
    .checkout-index-index .opc-wrapper.am-opc-wrapper .amcheckout-delivery-date .field {
        & {
            display: block;
            width: 50%;
        }
        &:nth-child(2n) {
            padding-left: 7px;
        }
        &.datepicker .control,
        &.timepicker .control,
        &.comment {
            width: 100%;
        }
    }
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__l) {
    .checkout-index-index .opc-wrapper.am-opc-wrapper .shipping-address-item {
        width: 50%;
    }
}
