//
//  Slick
//  ---------------------------------------------

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.product-items {
        padding: 0;

        @include min-screen($screen__m) {
            padding: 0 $indent__xxl;
        }
    }
    &.slick-initialized {
        display: block !important;

        + .slick-loading {
            display: none;
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
[data-content-type="slide"] + [data-content-type="slide"] {
    display: none;
}
.slick-slide {
    position: relative;
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;

    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: inline-block;
        //float: none;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.pagebuilder-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pagebuilder-collage-content h2 {
    font-size: 6rem;
    font-weight: bold;
    color: #fff;
    margin-top: 0;
    margin-bottom: 30px;
}

.pagebuilder-button-primary {
    text-transform: uppercase;
    color: #200041;
    font-size: 1.6rem;
    padding: 15px 50px;
    font-weight: bold;
}

.pagebuilder-slide-wrapper.jarallax iframe {
    height: 100% !important;
    margin: 0 !important;
    transform: none !important;
}

//
//  Slick Loading
//  ---------------------------------------------

.slick-loading,
.widget-loading {
    width: 100%;
    text-align: center;
    margin: ($indent__base * 3) 0;
}

//
//  Slick Dots
//  ---------------------------------------------

.slick-dots {
    @include lib-list-reset-styles();
    position: absolute;
    bottom: $indent__base;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0;

    li {
        display: inline-block;
        margin: 0 $indent__xs;

        button {
            display: block;
            width: 14px;
            height: 14px;
            border: 2px solid $color-white;
            border-radius: 7px;
            background-color: transparent;
            text-indent: -999em;
            overflow: hidden;
            padding: 0;

            &:hover {
                background-color: $color-white;
            }
        }
        &.slick-active {
            button {
                background-color: $color-white;
            }
        }
    }
}

//
//  Slick Arrows
//  ---------------------------------------------

.slick-arrow {
    display: block;
    @include lib-css(transition, opacity 0.15s, 1);
    position: absolute;
    background-color: $gray-lightest__color;
    border: 1px solid $border__color;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 38px;
    top: 50%;
    margin-top: -20px;
    opacity: 1;
    z-index: 100;
    cursor: pointer;
    padding: 0;
    @include lib-font-size(18px);

    &:hover,
    &:active {
        opacity: 0.75;
        background-color: $gray-lightest__color;
    }
}
.slick-next {
    right: $indent__m;
    padding-left: 3px;
}
.slick-prev {
    left: 0;
    padding-right: 1px;
}
.slick-disabled {
    opacity: 0.25;
    cursor: default;

    &:hover {
        opacity: 0.25;
    }
}
