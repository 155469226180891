$grid-gutter-width: 30px;
$grid-columns: 12;

@import 'grid-framework';
@import 'grid-system';
@import 'grid';

.columns {
    @include lib-layout-columns();

    &:before,
    &:after {
        width: 100%;
    }
    .column.main {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-order(2);
        max-width: 100%;
    }
    .sidebar-main {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-order(1);
        max-width: 100%;
    }
    .sidebar-additional {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-order(3);
        max-width: 100%;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .columns {
        .column.main {
            @include lib-vendor-prefix-order(3);
        }
        .sidebar-main {
            @include lib-vendor-prefix-order(1);
        }
        .sidebar-additional {
            @include lib-vendor-prefix-order(2);
        }
    }
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .page-header .header.nav,
    .page-header .header.promo,
    .header.content,
    .footer.mailing,
    .footer.content,
    .footer.bottom,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container {
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
    }
    .page-main {
        padding-top: $indent__m;
        padding-bottom: $indent__m;

        .cms-index-index & {
            padding-top: 0;
            padding-bottom: 0;
        }
        .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .page-header .header.nav,
    .page-header .header.promo,
    .header.content,
    .footer.mailing,
    .footer.content,
    .footer.bottom,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
        width: auto;
    }

    .page-main {
        width: 100%;
        flex: 1 0 auto;
        padding-top: $indent__l;
        padding-bottom: $indent__l;

        .cms-index-index & {
            padding-top: 0;
            padding-bottom: 0;
        }
        .ie9 & {
            width: auto;
        }
    }

    .columns {
        @include make-row();

        .column.main {
            padding-left: ($grid-gutter-width / 2);
            padding-right: ($grid-gutter-width / 2);

            .page-layout-3columns &,
            .page-layout-3columns & {
                @include lib-vendor-prefix-flex-grow(0);
                @include lib-vendor-prefix-flex-basis(50%);
                max-width: 50%;

                @include min-screen($screen__xl) {
                    @include lib-vendor-prefix-flex-basis(60%);
                    max-width: 60%;
                }
            }
            .page-layout-2columns-right &,
            .page-layout-2columns-left & {
                @include lib-vendor-prefix-flex-grow(0);
                @include lib-vendor-prefix-flex-basis(75%);
                max-width: 75%;

                @include min-screen($screen__xl) {
                    @include lib-vendor-prefix-flex-basis(80%);
                    max-width: 80%;
                }
            }
        }
        .sidebar-main,
        .sidebar-additional {
            padding-left: ($grid-gutter-width / 2);
            padding-right: ($grid-gutter-width / 2);
            @include lib-vendor-prefix-flex-grow(0);
            @include lib-vendor-prefix-flex-basis(25%);
            max-width: 25%;

            @include min-screen($screen__xl) {
                @include lib-vendor-prefix-flex-basis(20%);
                max-width: 20%;
            }
        }
    }
}
