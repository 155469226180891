//
//  Homepage
//  _____________________________________________

//
//  Homepage Slider
//  ---------------------------------------------

.homepage-slider {
    .slides {
        display: none;
        font-size: 0;
    }
    .slide {
        display: block;
    }
    .hidden-mobile {
        @include max-screen($screen__m) {
            display: none;
        }
    }
    .visible-mobile {
        display: none;

        @include max-screen($screen__m) {
            display: block;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .container-fluid {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .box {
        text-align: center;

        h2 {
            @include lib-font-size(60px);
            font-weight: bold;
            color: $color-white;
            margin-top: 0;
            margin-bottom: $indent__l;

            @include max-screen($screen__xl) {
                @include lib-font-size(50px);
                margin-bottom: $indent__base;
            }
            @include max-screen($screen__l) {
                @include lib-font-size(40px);
                margin-bottom: $indent__s;
            }
            @include max-screen($screen__s) {
                @include lib-font-size(30px);
            }
            @include max-screen($screen__xs) {
                @include lib-font-size(20px);
            }
        }
    }
    .slick-slide {
        img {
            width: 100%;
        }
    }
}

//
//  Homepage Featured Categories
//  ---------------------------------------------

.homepage-featured-categories {
    background-color: $gray-lightest__color;
    padding: ($indent__base * 3) 0 0;

    @include max-screen($screen__l) {
        padding: $indent__l 0 0;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img {
        display: block;
    }
    .relative {
        position: relative;
    }
    .col {
        @include max-screen($screen__s) {
            margin-bottom: $indent__m;
        }
    }
    .box {
        text-align: center;
        padding: 0 $indent__m;

        h2 {
            @include lib-font-size(35px);
            color: $color-white;
            margin-top: 0;
            margin-bottom: $indent__xs;

            @include max-screen($screen__l) {
                @include lib-font-size(24px);
            }
        }
        p {
            @include lib-font-size(16px);
            color: $color-white;
            margin-bottom: $indent__base;

            @include max-screen($screen__l) {
                @include lib-font-size(14px);
                margin-bottom: $indent__s;
            }
        }
        .buttons {
            a {
                margin: 0 $indent__s;
                @extend .action.white;

                @include max-screen($screen__l) {
                    float: none;
                    display: block;
                    margin: 0;

                    & + a {
                        margin: $indent__s 0 0;
                    }
                }
            }
        }
    }
}

//
//  Homepage About
//  ---------------------------------------------

.homepage-about {
    text-align: center;
    background-color: $gray-lightest__color;
    padding: ($indent__base * 3) 0;

    @include max-screen($screen__l) {
        padding: $indent__l 0;
    }

    h2 {
        @include lib-font-size(24);
        margin-top: 0;
    }
    p {
        @include lib-font-size(16);
    }
}

//
//  Homepage Categories
//  ---------------------------------------------

.homepage-categories {
    @extend .abs-add-clearfix;
    padding: ($indent__base * 3) 0 $indent__l;

    @include max-screen($screen__l) {
        padding: $indent__l 0 0;
    }
    @include max-screen($screen__xs) {
        padding: $indent__l 0 $indent__m;
    }

    img {
        display: block;
    }
    .col {
        position: relative;
        margin-bottom: $indent__l;

        @include max-screen($screen__l) {
            &:last-child {
                display: none;
            }
        }
        @include max-screen($screen__xs) {
            margin-bottom: $indent__m;

            &:last-child {
                display: block;
            }
        }
    }
    a {
        position: absolute;
        bottom: $indent__xl;
        left: 20%;
        width: 60%;
        background-color: rgba(255,255,255,0.8);
        text-transform: uppercase;
        color: $primary__color;
        text-align: center;
        padding: $indent__m 0;

        &:hover {
            text-decoration: none;
            background-color: rgba(255,255,255,0.9);
        }
        strong {
            @include lib-font-size(22);
        }
        span {
            @include lib-font-size(12);
        }
    }
}

//
//  Homepage Featured
//  ---------------------------------------------

.homepage-featured {
    margin-bottom: $indent__m;

    h2 {
        display: inline-block;
        @include lib-font-size(22px);
        margin: 0;

        span {
            margin-right: $indent__m;
        }
        small {
            @include lib-font-size(16px);

            @include max-screen($screen__m) {
                display: inline-block;
            }
            @include max-screen($screen__xs) {
                @include lib-font-size(13px);
            }
        }
    }
}

.cms-index-index {
    .main {
        .block {
            margin: 0;
        }
        & > hr {
            border-color: #e3e3e3;
            margin: $indent__xl 0;

            @include max-screen($screen__l) {
                margin: $indent__l 0;
            }
            @include max-screen($screen__m) {
                margin: $indent__xl 0 $indent__base;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

//
//  Homepage Links
//  ---------------------------------------------

.homepage-links {
    .row {
        margin-right: 0;
    }
    .col {
        padding-right: 0;

        @include min-screen($screen__xs) {
            @include max-screen($screen__s) {
                &:nth-of-type(2n+1) {
                    clear: left;
                }
            }
        }
    }
    .link {
        background-color: $gray-lightest__color;
        text-align: center;
        margin-bottom: $indent__l;

        img {
            display: block;
        }
        h2 {
            @include lib-font-size(23);
            font-weight: normal;
            margin: $indent__base 0 $indent__s;

            @include max-screen($screen__l) {
                @include lib-font-size(18);
            }
        }
        p {
            margin: 0 $indent__m $indent__base;
        }
        .action {
            margin-bottom: $indent__l;

            @include max-screen($screen__l) {
                margin-bottom: $indent__m;
            }
        }
    }
}

//
//  Footer Brands
//  ---------------------------------------------

.footer-brands {
    margin-bottom: $indent__s;

    .brands {
        a {
            @include make-xxs-column(6);
            @include make-m-column(4);
            @include make-l-column(2);
            font-size: 0;
            text-align: center;
            margin-bottom: $indent__base;

            img {
                display: inline-block;
                max-width: 80%;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}

//
//  Other
//  _____________________________________________

//
//  Category List
//  ---------------------------------------------

.category-list {
    margin-top: $indent__base;

    a {
        display: block;
        margin-bottom: $indent__l;

        img {
            display: block;
            width: 100%;
        }
        span {
            display: block;
            background-color: $primary__color;
            font-weight: 500;
            text-transform: uppercase;
            color: $primary__color;
            padding: $indent__m $indent__base;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:before {
                float: right;
                @extend .fas;
                content: fa-content($fa-var-chevron-right);
                @include lib-font-size(12px);
                margin-top: 4px;
                margin-left: $indent__s;
            }
        }
        &:hover {
            text-decoration: none;

            span {
                color: #0960b3;
            }
        }
    }
}

//
//  Sidebar List
//  ---------------------------------------------

.sidebar-list {
    @include max-screen($screen__m) {
        display: none;
    }

    ul {
        @extend .abs-reset-list;

        > li {
            > a {
                display: block;
                color: $primary__color;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: #0960b3;
                }
            }
        }
    }
    .level1 {
        > li {
            > a {
                padding: $indent__base 0;
                border-top: 1px solid $border__color;
                text-transform: uppercase;
                font-weight: 500;

                @include max-screen($screen__m) {
                    padding: $indent__s 0;
                }
            }
            &.has-subs {
                > a {
                    &:before {
                        float: right;
                        @extend .fas;
                        content: fa-content($fa-var-chevron-down);
                        font-size: 12px;
                        margin-top: 4px;
                    }
                }
            }
            &.active {
                > ul {
                    display: block;
                }
                &.has-subs {
                    > a {
                        &:before {
                            content: fa-content($fa-var-chevron-up);
                        }
                    }
                }
            }
            &:first-child {
                > a {
                    border: 0;
                }
            }
        }
    }
    .level2 {
        display: none;

        > li {
            > a {
                margin-bottom: $indent__base;
                font-size: 13px;
                font-weight: 300;

                @include max-screen($screen__m) {
                    margin-bottom: $indent__s;
                }
            }
        }
    }
}

//
//  CMS Styling
//  ---------------------------------------------

.cms-stockists-worldwide {
    table {
        tbody {
            tr {
                td {
                    padding: 5px 0;
                    width: 33.3333%;
                    text-transform: uppercase;
                }
            }
        }
    }
}
