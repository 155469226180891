//
//  Smile\Variables
//  --------------------------------------------

$autocomplete__background-color:                #ffffff;
$autocomplete__border:                          $border__color;
$autocomplete-item__border:                     $border__color;
$autocomplete-item__hover__color:               #e6e7e9;
$autocomplete-item-amount__color:               $primary__color;

//
//  Smile\ElasticsuiteCore
//  --------------------------------------------

.search-autocomplete {
    position: absolute;
    z-index: 105;
    overflow: hidden;
    margin-top: -1px;
    @extend .abs-add-box-sizing;

    dl {
        @include lib-list-reset-styles();

        dt {
            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, 1px solid $autocomplete__border);
                border-bottom: 0;
            }
            @include lib-css(border-top, 1px solid $autocomplete-item__border);
            cursor: default;
            margin: 0;
            padding: $indent__s $indent__xl $indent__s $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;
        }
        dd {
            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, 1px solid $autocomplete__border);
                border-top: 0;
            }
            @include lib-css(border-top, 1px solid $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:first-child {
                border-top: none;
            }
            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }
            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
            .clear {
                clear:both;
            }
        }
    }
}

//
//  Smile\ElasticsuiteCatalog
//  --------------------------------------------

.smile-es-range-slider {
    [data-role=from-label] {
        display: block;
        float: left;
        padding: 0 0 $indent__xs;
    }
    [data-role=to-label] {
        display: block;
        float: right;
        padding: 0 0 $indent__xs;
    }
    [data-role=message-box] {
        font-size: $font-size__s;
        flex: 1;
        padding: 6px 0 0;

        &.empty {
            @include lib-form-validation-note();
            padding: 0;
        }
    }
    .actions-toolbar {
        display: flex;
        margin: 15px 0;
    }
    .ui-slider {
        margin: $indent__xs;
        clear: both;
    }
    .ui-slider-handle {
        @include lib-css(background, $active__color);
        padding: 0;
        margin: -3px 0 0 -7px;
        border-radius: 15px;
        width: 15px;
        height: 15px;
    }
}

.filter {
    .filter-current {
        display: none;
    }
    .filter-clear {
        @extend .action.secondary;
    }
    .filter-content .item {
        margin: $indent__s 0 0 0;

        input[type="checkbox"] {
            display: none;

            &:checked {
                &+label {
                    color: $link__color;
                    text-decoration: underline;
                }
            }
        }
        label {
            cursor: pointer;
        }
    }
    .block-subtitle {
        border-bottom: none;
        font-weight: $font-weight__bold;
    }
    .field.search {
        input[type="text"] {
            font-size: $font-size__s;
        }
    }

    .actions {
        text-align: right;
        margin: $indent__s 0 0;

        div.secondary {
            display: inline-block;

            a {
                @include lib-css(color, $link__color);
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    background: transparent;
                }
                &.show-more {
                    span:after {
                        content: " +";
                    }
                }
                &.show-less {
                    span:after {
                        content: "-";
                    }
                }
            }
        }
    }
    .filter-options {
        dt.filter-options-title[aria-expanded="true"]:after {
            @include lib-css(content, $icon-up);
        }
    }
    .no-results-message p {
        margin: 9px $indent__s 0;
        background-color: #FFEEEE;
        padding: $indent__xs;
        color: #AA0000;
    }
}

.smile-elasticsuite-autocomplete-result {
    .product-image-box {
        float: left;
        padding: 0 $indent__xs;
        width: 55px;
    }
    .product-shop {
        float: left;
        margin: 0 $indent__s 0 0;
        padding: 0;
        white-space: normal;
        width: 60%;
    }
    .product-item {
        .price-box {
            margin: $indent__xs 0;
        }
    }
    .category-mini-crumb {
        font-style: italic;
        display: block;
        margin-bottom: 2px;
        font-size: $font-size__s;
        color: #929292;
    }
    .product-attribute-label {
        text-transform: uppercase;
        vertical-align: super;
        font-size: $font-size__xs;
        color: #777;
    }
    .price-box {
        span.old-price {
            display:block;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-layout-1column {
        .filter-options {
            .filter {
                &-options {
                    &-content {
                        padding: $indent__s;
                    }
                }
            }
        }
    }
}
