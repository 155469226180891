// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='slider'] {
    visibility: hidden;
  
    &.slick-initialized {
      visibility: visible;
    }
  
    .slick-list,
    .slick-track,
    .slick-slide {
      min-height: inherit;
  
      > div {
        line-height: 0;
        min-height: inherit;
        overflow: hidden;
        width: 100%;
      }
    }
  
    a.button {
      -moz-appearance: button;
      -webkit-appearance: button;
      appearance: button;
      color: initial;
      padding: 10px;
      text-decoration: none;
    }
  }
  