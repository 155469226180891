//
// Grid system
// --------------------------------------------------

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
    @include container-fixed;
}

// Row
//
// Rows contain and clear the floats of your columns.

.row {
    @include make-row;
}


// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;

@include make-grid(xxs);

@include min-screen($screen__xs) {
    @include make-grid(xs);
}

@include min-screen($screen__s) {
    @include make-grid(s);
}

@include min-screen($screen__m) {
    @include make-grid(m);
}

@include min-screen($screen__l) {
    @include make-grid(l);
}

@include min-screen($screen__xl) {
    @include make-grid(xl);
}
