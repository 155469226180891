//
//  Widgets
//  _____________________________________________

.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block.widget {
    .products-grid {
        .widget-product-grid {
            display: none;
        }
    }
    .ias-noneleft {
        display: none !important;
    }
}

.block-viewed-products-grid {
    .block-title {
        border-bottom: 1px solid $border__color;
        padding-bottom: $indent__m;
        margin-bottom: $indent__l;

        @include max-screen($screen__m) {
            margin-top: $indent__m;
        }

        strong {
            @include lib-font-size(16px);
            font-weight: normal;
        }
    }
}
