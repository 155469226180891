/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

// Default appearance styles
// _____________________________________________
@media screen {
    [data-content-type='banner'] > [data-element='link'],
    [data-content-type='banner'] > [data-element='empty_link'] {
        color: inherit;
        text-decoration: inherit;

        &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }

    .pagebuilder-banner-wrapper {
        background-clip: padding-box;
        border-radius: inherit;
        box-sizing: border-box;
        overflow-wrap: break-word;
        word-wrap: break-word;

        .pagebuilder-overlay {
            transition: background-color 500ms ease;
            box-sizing: border-box;
            padding: 30px;
            position: relative;

            &.pagebuilder-poster-overlay {
                align-items: center;
                display: flex;
                justify-content: center;
            }

            &:not(.pagebuilder-poster-overlay) {
                max-width: 540px;
            }
        }

        &.jarallax {
            .video-overlay {
                z-index: 0;
            }
        }

        [data-element='content'] {
            min-height: 50px;
            overflow: auto;
        }

        .pagebuilder-banner-button {
            transition: opacity 500ms ease;
            margin: 20px 0 0;
            max-width: 100%;
            text-align: inherit;
            word-break: break-word;
        }
    }
}

// Mobile (style-m.css)
// _____________________________________________
@media (max-width: 768px) { // Replace with the actual value of `@screen__m`
    .pagebuilder-banner-wrapper {
        background-attachment: scroll !important;

        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}
