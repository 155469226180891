//
//  Newsletter
//  ---------------------------------------------

.block.newsletter {
    margin-bottom: -$indent__m;
    width: 25%;
    display: inline-block;

    @include max-screen($screen__l) {
        width: 40%;
        margin-left: $indent__base;
    }
    @include max-screen($screen__s) {
        width: 100%;
        margin: 0;
    }

    .title {
        display: none;
    }
    .content {
        .form.subscribe {
            display: table;
            width: 100%;

            .field.newsletter {
                .label {
                    @extend .abs-visually-hidden;
                }
                .control {
                    input {
                        height: 42px;
                    }
                }
            }
            .actions {
                width: 1%;
                display: table-cell;
                vertical-align: top;
            }
        }
    }
}
