// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Mobile (style-m.css)
//  _____________________________________________

@media (max-width: $screen__m) { // Replace with actual screen width if needed
    .pagebuilder-column-group {
      flex-wrap: wrap;
    }
  }
  