// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Collage center appearance styles
//  _____________________________________________

[data-appearance='collage-centered'] {
    .pagebuilder-slide-wrapper {
      .pagebuilder-overlay {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  