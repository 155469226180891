//
//  Product tabs
//  ---------------------------------------------

.product.data.items {
    @include min-screen($screen__m) {
        @include lib-data-tabs();

        & > .item.title {
            & > .switch {
                @include max-screen($screen__xl) {
                    padding-left: $indent__base;
                    padding-right: $indent__base;
                }
            }
        }
    }
    @include max-screen($screen__m) {
        @include lib-data-accordion();

        & > .item.title {
            &.active {
                & > .switch {
                    color: $color-white;
                }
            }
        }
    }
}
