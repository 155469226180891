// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='map'] {
    box-sizing: border-box;
    height: 300px;
  }
  