.breadcrumbs {
    @include lib-breadcrumbs();
    min-height: 20px;
    padding: $indent__l 0;

    @include max-screen($screen__l) {
        padding: $indent__base 0;
    }

    .item {
        &:after {
            content: "/";
            color: #cbcbcb;
            margin: 0 $indent__xs;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
