//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : $color-white !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : $gray-lightest__color;


body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Back to top button
//  ---------------------------------------------

.page-wrapper {
    .back-to-top {
        @include lib-css(transition, all 0.2s);
        position: fixed;
        right: $indent__m;
        bottom: 10%;
        background-color: $primary__color;
        color: $color-white;
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        z-index: 200;
        border-radius: 50%;

        &:hover {
            background-color: $secondary__color;
        }
        span {
            display: none;
        }
    }
}

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    position: relative;
    z-index: 20;
}

.header.content {
    @extend .abs-add-clearfix;
    position: relative;

    @include max-screen($screen__m) {
        text-align: center;
        padding-top: $indent__s;
    }
}

.logo {
    display: inline-block;
    margin: 24px 0 $indent__l;
    position: relative;
    z-index: 5;

    @include max-screen($screen__l) {
        margin-top: 22px;
    }
    @include min-screen($screen__m) {
        float: left;
    }
    @include max-screen($screen__m) {
        width: 100px;
        margin: 0;
    }

    img {
        display: block;
    }
    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Top Panel
//  ---------------------------------------------

.panel.wrapper {
    background-color: $primary__color;

    @include max-screen($screen__m) {
        display: none;
    }

    .panel.header {
        @extend .abs-add-clearfix;

        .widget.block {
            float: left;
            margin: 0;

            .goog-te-gadget {
                width: 180px;
                color: $color-white;

                @include max-screen($screen__xs) {
                    width: 132px;
                }

                select {
                    margin: 2px 0;
                    background-color: $color-white !important;
                    background-image: url('../images/select.png');
                    background-repeat: no-repeat;
                    background-position: top right 10px;
                    opacity: 1.0 !important;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                a {
                    color: $color-white;
                }
            }
        }
        .panel.message {
            float: left;
            padding: $indent__s 0;

            p {
                margin: 0;
                color: $color-white;
                text-transform: uppercase;
            }
        }
        .order.hotline {
            float: right;
            padding: 6px 0 8px $indent__m;

            @include max-screen($screen__l) {
                display: none;
            }

            p {
                color: $color-white;
                margin: 0;
                @include lib-font-size(18px);

                span {
                    @include lib-font-size(16px);
                }
                a {
                    color: $color-white;
                }
            }
        }
        .header.links {
            @include lib-list-reset-styles();
            float: right;

            @include max-screen($screen__m) {
                display: none;
            }

            li {
                float: left;
                margin: 0;

                a {
                    display: block;
                    background-color: $secondary__color;
                    color: $color-white;
                    padding: $indent__s $indent__m;

                    &:hover {
                        background-color: $color-white;
                        color: $primary__color;
                        text-decoration: none;
                    }
                }
                &.wishlist {
                    a {
                        &:before {
                            @extend .fas;
                            @include fa-icon;
                            content: fa-content($fa-var-heart);
                            @include lib-font-size(19px);
                            vertical-align: -0.05em;

                            @include min-screen($screen__xl) {
                                @include lib-font-size(17px);
                                margin-right: $indent__xs;
                            }
                        }
                        @include max-screen($screen__xl) {
                            font-size: 0;
                        }
                        .counter {
                            &:before {
                                content: "(";
                            }
                            &:after {
                                content: ")";
                            }
                        }
                    }
                }
                &.authorization-link {
                    a {
                        &:before {
                            @extend .fas;
                            @include fa-icon;
                            content: fa-content($fa-var-user);
                            @include lib-font-size(19px);
                            vertical-align: -0.05em;

                            @include min-screen($screen__xl) {
                                @include lib-font-size(17px);
                                margin-right: $indent__xs;
                            }
                        }
                        @include max-screen($screen__xl) {
                            font-size: 0;
                        }
                    }
                    & + .authorization-link {
                        a.login {
                            &:before {
                                content: fa-content($fa-var-sign-in-alt);
                            }
                        }
                        a.logout {
                            &:before {
                                content: fa-content($fa-var-sign-out-alt);
                            }
                        }
                    }
                }
                & + li {
                    a {
                        border-left: 1px solid $primary__color;
                    }
                }
                &.switcher-heading,
                &.switcher-option {
                    display: none;
                }
            }
        }
    }
}

//
//  Promo
//  ---------------------------------------------

.promo.wrapper {
    border-top: 1px solid $primary__color;
    border-bottom: 1px solid #eeeff0;

    @include max-screen($screen__s) {
        display: none;
    }

    .cms-index-index & {
        border-bottom: 0;
    }

    .promo.header {
        text-align: center;

        ul {
            @extend .abs-add-clearfix;
            @extend .abs-reset-list;
            padding-top: $indent__m;

            @include max-screen($screen__l) {
                @include make-row();
            }
            @include min-screen($screen__l) {
                display: flex;
                justify-content: space-between;
            }

            li {
                @include make-xxs-column(12);
                @include make-s-column(6);
                padding-bottom: $indent__m;

                @include min-screen($screen__l) {
                    float: none;
                    width: auto;
                }
                .fas {
                    @include lib-font-size(18px);
                    color: $secondary__color;
                    margin-right: $indent__s;

                    @include max-screen($screen__xl) {
                        margin-right: $indent__xs;
                    }
                }
            }
        }
    }
}

//
//  Important Message
//  ---------------------------------------------

.message.wrapper {
    background-color: #b36161;

    .message.header {
        text-align: center;

        p {
            @include lib-font-size(16px);
            color: $color-white;
            margin: 0;
            padding: $indent__s 0;
        }
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }
    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }
    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }
    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.footer {
    &.mailing {
        text-align: center;
        padding-top: $indent__xl;
        padding-bottom: $indent__xl + $indent__m;

        @include max-screen($screen__l) {
            padding-top: $indent__base;
            padding-bottom: $indent__base + $indent__m;
        }
        @include max-screen($screen__s) {
            padding-bottom: $indent__base;
        }

        .content {
            font-size: 0;

            h2 {
                display: inline-block;
                @include lib-font-size(18px);
                margin: 0;

                @include max-screen($screen__s) {
                    margin-bottom: $indent__s;
                }
            }
            p {
                display: inline-block;
                @include lib-font-size(14px);
                margin: 0 $indent__base;

                @include max-screen($screen__l) {
                    display: none;
                }
            }
        }
    }
    &.content {
        @include max-screen($screen__m) {
            text-align: center;
        }

        .hidden-l-down {
            @include max-screen($screen__l) {
                display: none;
            }
        }
        .hidden-m-down {
            @include max-screen($screen__m) {
                display: none;
            }
        }
        .footer-brand {
            img {
                margin-top: $indent__base;
                margin-right: $indent__s;
            }
        }
        .content {
            border-top: 1px solid #dcddde;
            border-bottom: 1px solid #dcddde;
            padding-bottom: $indent__xl;
            padding-top: $indent__xl;

            @include max-screen($screen__l) {
                padding-top: $indent__l;
                padding-bottom: $indent__l;
            }
            @include max-screen($screen__m) {
                padding-top: $indent__base;
                padding-bottom: $indent__base;
            }
        }
        h3 {
            @include lib-font-size(14px);
            margin-bottom: $indent__base;
            margin-top: 0;

            .fas {
                margin-left: $indent__xs;

                @include min-screen($screen__xs) {
                    display: none;
                }
            }
        }
        a {
            color: $primary__color;
        }
        ul {
            @extend .abs-reset-list;

            @include min-screen($screen__xs) {
                display: block !important;
            }
            @include max-screen($screen__xs) {
                display: none;
            }
            @include max-screen($screen__m) {
                margin-bottom: $indent__base;
            }

            li {
                margin-bottom: $indent__base;

                @include max-screen($screen__m) {
                    margin-bottom: $indent__s;
                }
            }
        }
        .footer-social {
            @include lib-clearfix();
            margin-bottom: $indent__s;

            @include max-screen($screen__l) {
                text-align: center;
            }

            a {
                display: inline-block;
                width: 36px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                background-color: $primary__color;
                margin-left: $indent__xs;
                margin-bottom: $indent__xs;
                color: $color-white;
                @include lib-font-size(16px);
                border-radius: 50%;

                @include min-screen($screen__l) {
                    float: right;
                }

                &:hover {
                    background-color: $secondary__color;
                }
            }
        }
        .royal-warrant {
            position: relative;

            img {
                position: absolute;
                right: 0;
                width: 150px;
                max-width: none;

                @include max-screen($screen__xl) {
                    width: 130px;
                }
                @include max-screen($screen__l) {
                    top: -180px;
                }
                @include max-screen($screen__m) {
                    position: static;
                }
            }
        }
    }
    &.bottom {
        padding-top: $indent__l;
        padding-bottom: $indent__l;
        text-align: center;
        font-size: 0;

        @include max-screen($screen__l) {
            padding-top: $indent__base;
            padding-bottom: $indent__base;
        }

        .copyright {
            display: inline-block;

            @include min-screen($screen__l) {
                float: left;
                line-height: 31px;
            }
            @include max-screen($screen__l) {
                width: 100%;
                margin-bottom: $indent__s;
            }
        }
        img {
            display: inline-block;
        }
        .by {
            display: inline-block;

            @include min-screen($screen__l) {
                float: right;
                line-height: 31px;
            }
            @include max-screen($screen__l) {
                width: 100%;
                margin-bottom: $indent__s;
            }

            a {
                display: inline-block;
                margin-left: $indent__s;

                @include min-screen($screen__l) {
                    float: right;
                }

                img {
                    display: block;
                    position: relative;

                    @include min-screen($screen__l) {
                        top: 8px;
                    }
                    @include max-screen($screen__l) {
                        top: 4px;
                    }
                }
            }
        }
    }
}

//
//  Switcher
//  ---------------------------------------------

.page-header,
.page-footer {
    .switcher {
        display: none;
        float: right;
        padding: $indent__s $indent__m;
        border-right: 1px solid #634d7a;

        @include max-screen($screen__l) {
            border: 0;
            padding-right: 0;
        }
        @include max-screen($screen__s) {
            display: none;
        }

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            .action.toggle {
                color: $color-white;

                &:after {
                    display: none !important;
                }
                strong {
                    &:after {
                        margin-left: $indent__xs;
                        @extend .fas;
                        @include fa-icon;
                        content: fa-content($fa-var-caret-down);
                    }
                }
                &.active {
                    strong {
                        &:after {
                            content: fa-content($fa-var-caret-up);
                        }
                    }
                }
            }
            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .label {
            @extend .abs-visually-hidden;
        }
        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }
        .ie10 &,
        .ie11 & {
            display: block;
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .footer {
        &.content {
            .block {
                float: right;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}
