//
//  Magento\CatalogSearch
//  --------------------------------------------

.block-search {
    float: right;
    margin-bottom: $indent__s;
    margin-top: 37px;

    @include max-screen($screen__l) {
        margin-top: $indent__s;
    }
    @include max-screen($screen__m) {
        width: 100%;
    }

    .block {
        &-title {
            display: none;
        }
        &-content {
            margin-bottom: 0;
        }
    }
}

.minisearch {
    position: relative;

    .label {
        display: none;
    }
    .control {
        overflow: hidden;
    }
    #minisearch-input-top-search {
        @include lib-input-placeholder(#798fa7);
        background-color: $gray-lightest__color;
        border-color: $border__color;
        height: 48px;
        width: 500px;
        box-shadow: none;
        margin: 0;
        padding-right: 72px;
        z-index: 3;
        border-radius: 2px;

        @include max-screen($screen__xl) {
            width: 300px;
        }
        @include max-screen($screen__m) {
            width: 100%;
        }
    }
    .action.search {
        @include lib-button-reset();
        background-color: $primary__color;
        position: absolute;
        right: 0;
        top: 0;
        height: 48px;
        width: 72px;
        z-index: 5;
        color: $color-white;
        border-radius: 0 2px 2px 0;

        span {
            @extend .abs-visually-hidden;
        }
        &:disabled {
            opacity: 1;
            cursor: default;
        }
        &:before {
            @extend .fas;
            @include fa-icon();
            content: fa-content($fa-var-search);
            @include lib-font-size(20px);
        }
    }
}
