//
//  Filters
//  ---------------------------------------------

.block.filter {
    margin-bottom: $indent__xl;

    @include max-screen($screen__m) {
        margin-bottom: $indent__base;
    }

    .filter-title {
        background-color: $primary__color;
        margin-bottom: $indent__s;
        color: $color-white;
        padding: $indent__s $indent__base;

        @include max-screen($screen__m) {
            &:after {
                margin-left: $indent__xs;
                @include fas;
                @include fa-icon;
                content: fa-content($fa-var-chevron-down);
            }
            &.active {
                &:after {
                    content: fa-content($fa-var-chevron-up);
                }
            }
        }

        strong {
            @include lib-font-size(15px);
            font-weight: normal;
        }
    }
    .block-subtitle {
        display: none;
    }
    .filter-content {
        @include max-screen($screen__m) {
            display: none;
        }
        @include min-screen($screen__m) {
            display: block !important;
        }
    }
}

.filter-current {
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.filter-options {
    margin: 0;

    &-title {
        @include lib-font-size(15px);
        font-weight: $font-weight__bold;
        padding: $indent__s 0;
        border-bottom: 1px solid $border__color;
        margin: 0 0 $indent__s;

        @include max-screen($screen__m) {
            &:after {
                margin-left: $indent__xs;
                @include fas;
                @include fa-icon;
                content: fa-content($fa-var-chevron-down);
            }
            &.active {
                &:after {
                    content: fa-content($fa-var-chevron-up);
                }
            }
        }
    }

    &-content {
        margin: 0 0 $indent__m;
        display: none;

        @include min-screen($screen__m) {
            display: block !important;
        }

        .item {
            margin-bottom: $indent__s;

            a {
                color: $primary__color;
            }
        }
    }

    .count {
        &:before {
            content: '(';
        }
        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}
