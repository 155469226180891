// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

// Variables
$icons-pagebuilder__font-name: 'pagebuilder-font';
// $icons-pagebuilder-font-path: '#{$baseDir}Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons';

$icon-pagebuilder-caret-up__content: '\f104';
$icon-pagebuilder-caret-down__content: '\f101';
$icon-pagebuilder-caret-next__content: '\f102';
$icon-pagebuilder-caret-prev__content: '\f103';

// Import partials
@import '_icons';
@import '_layout';
@import 'slick/_slick';
@import 'content-type/_import';
@import 'jarallax/_jarallax';

// Common
.cms-content-important {
    background-color: $color-white-smoke;
    color: $color-gray20;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    margin: 0 0 35px -20px;
    padding: 20px;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}

// Styles for legacy content types
.pagebuilder-accordion {
    @include lib-data-accordion();
}

// Mobile (style-m.css)
@media (max-width: $screen__m) {
    .pagebuilder-mobile-hidden {
        display: none !important;
    }
}

// Desktop (style-l.css)
@media (min-width: $screen__m) {
    .pagebuilder-mobile-only {
        display: none !important;
    }
}
