// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

.pagebuilder-column {
    box-sizing: border-box;
  }
  
  //
  //  Mobile (style-m.css)
  //  _____________________________________________
  
  @media (max-width: $screen__m) { // Replace with actual screen width if needed
    .pagebuilder-column {
      background-attachment: scroll !important;
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  