//
//  Product Lists
//  _____________________________________________

.products-grid,
.products-list {
    .product {
        &-items {
            @include make-row;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            list-style: none;
            padding: 0;

            .page-products &,
            .block-products-list & {
                margin-left: 0;
            }
            .product-item {
                margin-bottom: $indent__m;

                .block.widget & {
                    margin-bottom: 0;
                }
            }
        }
        &-item {
            .page-products &,
            .block-products-list &,
            .wishlist-index-index & {
                @include make-xxs-column(12);
                @include make-xs-column(6);
                @include make-s-column(4);
                @include make-m-column(6);
                @include make-l-column(4);
                @include make-xl-column(3);
                padding-left: 0;

                @include min-screen(360px) {
                    @include max-screen(480px) {
                        width: 50%;
                    }
                }

                &-info {
                    @extend .abs-add-clearfix;
                    max-width: 100%;
                    border: 1px solid #e5e8e9;
                    padding: $indent__base;

                    @include max-screen($screen__xs) {
                        padding: $indent__m;
                    }
                }
            }
            .page-main .block.related &,
            .page-main .block.upsell & {
                @include make-xxs-column(12);
                @include make-m-column(6);
            }
            .block-viewed-products-grid &,
            .block.crosssell & {
                @include make-xxs-column(12);
                @include make-xs-column(6);
                @include make-m-column(4);
                @include make-l-column(3);
                @include make-xl-column(2);

                @include min-screen(360px) {
                    @include max-screen(479px) {
                        width: 50%;
                    }
                }

                &-item {
                    @include max-screen($screen__xl) {
                        &:nth-of-type(n+4) {
                            display: none;
                        }
                    }
                    @include max-screen($screen__l) {
                        &:nth-of-type(n+3) {
                            display: none;
                        }
                    }
                    @include max-screen($screen__m) {
                        &:nth-of-type(n+2) {
                            display: none;
                        }
                    }
                }
            }
            &-details {
                position: relative;
            }
            &-photo {
                display: block;
                margin-bottom: $indent__base;

                &.second-photo {
                    &:hover {
                        opacity: 0;

                        &+.product-item-second-photo {
                            opacity: 1;
                        }
                    }
                }
            }
            &-second-photo {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: -1;
            }
            &-name {
                @extend .abs-product-link;
                display: inline-block;
                margin-bottom: $indent__s;
                word-wrap: break-word;
                hyphens: auto;
                @include lib-font-size(15px);
                overflow: hidden;
                height: 42px;

                @include max-screen($screen__l) {
                    height: 63px;
                }

                .block-viewed-products-grid & {
                    @include lib-font-size(14px);

                    height: 40px;

                    @include max-screen($screen__l) {
                        height: 60px;
                    }
                }
            }
            &-sku {
                margin-bottom: $indent__m;
                @include lib-font-size(13px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                .ref {
                    @include max-screen($screen__m) {
                        display: none;
                    }
                }
            }
            &-swatches {
                @include lib-clearfix();
                height: 25px;
                overflow: hidden;

                .swatch {
                    float: left;
                    width: 20px;
                    height: 20px;
                    margin-right: $indent__xs;
                    margin-bottom: $indent__xs;
                    border-radius: 10px;

                    &.white {
                        width: 18px;
                        height: 18px;
                        border: 1px solid #e2e2e2;
                    }
                    &.missing {
                        width: 16px;
                        height: 16px;
                        border: 2px solid red;
                    }
                }
                .text {
                    float: left;
                    text-align: center;
                    line-height: 0.9;
                    margin-left: $indent__xs;
                    @include lib-font-size(13px);
                }
            }
            &-description {
                display: none;
            }
            hr {
                border-color: #e5e8e9;
                margin: $indent__m 0;
            }
            .actions-secondary {
                white-space: pre-line;
                line-height: 0;

                & > .action.towishlist {
                    position: absolute;
                    right: -5px;
                    bottom: 60px;
                    z-index: 100;

                    &:before {
                        @include fa-icon();
                        @include fas();
                        content: fa-content($fa-var-heart) !important;
                        @include lib-font-size(18px);
                        color: $border__color;
                    }
                    span {
                        display: none;
                    }
                }
                & > .action.tocompare {
                    display: none;
                }
            }
            &-stock {
                margin-bottom: $indent__s;

                @include min-screen(360px) {
                    @include max-screen($screen__m) {
                        font-size: 12px;

                        .fas {
                            display: none;
                        }
                    }
                }

                &.available {
                    color: #08ae00;
                }
                &.backorder {
                    color: #6f4400;
                }
                &.unavailable {
                    color: #c82f2f;
                }
            }
            .price-box {
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: $indent__m;

                .price-container {
                    padding-top: 18px;
                    white-space: nowrap;
                }
                .old-price,
                .special-price {
                    .price-container {
                        padding-top: 0;
                    }
                }
                .normal-price,
                .special-price {
                    .price-including-tax {
                        display: none;
                    }
                }
                & + .product-item-button {
                    margin: 0;
                }
            }
            &-tooltip {
                & + .product-item-inner {
                    padding-top: 0;
                }
            }
            &-inner {
                padding-top: 19px;
            }
            &-button {
                @extend .action.default;
                width: 100%;
                text-align: center;
                margin-top: 56px;

                @include max-screen($screen__m) {
                    padding: 8px;
                    @include lib-font-size(12px);
                }
            }
            &-actions {
                display: none;
            }
        }
    }
    .slick-dots {
        position: static;
        margin-top: $indent__m;

        li {
            button {
                border-color: $primary__color;

                &:hover {
                    background-color: $primary__color;
                }
            }
            &.slick-active {
                button {
                    background-color: $primary__color;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .page-products {
        .products-list {
            .product {
                &-items {
                    margin: 0;
                }
                &-item {
                    padding: 0;
                    width: 100%;

                    &-photo {
                        float: left;
                        width: 220px;
                        margin: 0 $indent__xl 0 0;

                        @include max-screen($screen__xl) {
                            margin-right: $indent__base;
                        }
                    }
                    &-info {
                        padding-left: $indent__xl;
                        padding-right: 320px;

                        @include max-screen($screen__xl) {
                            padding-left: $indent__base;
                            padding-right: 260px;
                        }
                    }
                    &-name {
                        display: inline;
                        @include lib-font-size(18px);
                        height: auto;
                    }
                    &-description {
                         display: block;
                        height: 22px;
		        overflow: hidden;
                    }
                    hr {
                        display: none;
                    }
                    &-right {
                        box-sizing: border-box;
                        position: inherit;
                        right: 0;
                        top: 0;
                        height: 100%;

                        @include max-screen($screen__xl) {
                            padding: $indent__base;
                        }
                    }
                    &-button {
                        width: auto;
                        padding-left: $indent__xl;
                        padding-right: $indent__xl;
                    }
                }
            }
        }
    }
}
