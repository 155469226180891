//
//  Category Top
//  ---------------------------------------------

.category-top {
    background-color: $gray-lightest__color;
    margin-bottom: $indent__s;

    @include max-screen($screen__m) {
        padding-top: $indent__base;
    }

    .container-fluid {
        padding-top: $indent__xxl;
    }
    h1 {
        @include lib-font-size(28px);
        margin-bottom: $indent__l;

        @include max-screen($screen__l) {
            margin-bottom: $indent__base;
        }
        @include max-screen($screen__m) {
            margin-bottom: $indent__s;
        }
        @include max-screen($screen__xs) {
            @include lib-font-size(30px);
        }
    }
    .category-description {
        @include lib-font-size(16px);
        margin-bottom: $indent__base;

        @include max-screen($screen__xs) {
            @include lib-font-size(14px);
        }
    }
    .image {
        position: static;
        
        @include max-screen($screen__xl) {
            text-align: center;
            font-size: 0;
        }
    }
    .message {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $indent__m;
        z-index: 10;
        background-color: #512d6d;
        color: $color-white;
        @include lib-font-size(15px);
        text-transform: uppercase;
        padding: $indent__l;
        text-align: center;

        @include max-screen($screen__xl) {
            transform: translateX(50%);
            right: 50%;
            bottom: 0;
            top: auto;
            z-index: 16;
            padding: $indent__base;
        }
        @include max-screen($screen__l) {
            padding: $indent__base $indent__m;
        }
        @include max-screen($screen__m) {
            display: none;
        }
    }
    img {
        position: relative;
        z-index: 15;
        display: inline-block;
        margin-top: -$indent__xl;

        @include max-screen($screen__l) {
            margin-top: -$indent__base;
        }
        @include max-screen($screen__m) {
            display: none;
        }
    }
}

//
//  Category Landing Top
//  ---------------------------------------------

.category-landing-top {
    background-color: $gray-lightest__color;
    margin-bottom: $indent__xxl;

    @include max-screen($screen__l) {
        margin-bottom: $indent__xl;
    }
    @include max-screen($screen__m) {
        margin-bottom: $indent__base;
    }

    .container-fluid {
        text-align: center;
    }
    h1 {
        @include lib-font-size(36px);

        @include max-screen($screen__l) {
            margin-bottom: $indent__s;
        }
        @include max-screen($screen__xs) {
            @include lib-font-size(30px);
        }
    }
    .category-description {
        @include lib-font-size(16px);
        margin-bottom: $indent__base;

        @include max-screen($screen__l) {
            margin-bottom: $indent__s;
        }
        @include max-screen($screen__xs) {
            @include lib-font-size(14px);
        }
    }
    img {
        @include max-screen($screen__l) {
            display: none;
        }
    }
    .cms {
        @include max-screen($screen__l) {
            margin-bottom: $indent__s;
        }

        a {
            @extend .action.white;
            margin: 0 $indent__xs;

            @include max-screen($screen__l) {
                margin-bottom: $indent__s;
            }
        }
    }
}

//
//  Category Landing Linked Categories
//  ---------------------------------------------

.category-landing-linked-categories {
    .col {
        @include min-screen($screen__xl) {
            width: 20%;
        }
    }
    .linked-category {
        text-align: center;
        margin-bottom: $indent__xl;

        @include max-screen($screen__l) {
            margin-bottom: $indent__l;
        }
        @include max-screen($screen__m) {
            margin-bottom: $indent__base;
        }

        .image {
            display: block;
            background-color: $gray-lightest__color;
            font-size: 0;

            .category-shop-by-brands & {
                padding: $indent__base;
            }
            img {
                display: inline-block;
            }
            &:hover {
                img {
                    opacity: 0.9;
                }
            }
        }
        h2 {
            @include lib-font-size(18px);
            text-transform: uppercase;
            padding: $indent__base 0;
            margin: 0;

            @include max-screen($screen__l) {
                padding: $indent__s 0;
            }

            .category-shop-by-brands & {
                display: none;
            }
            a {
                color: $primary__color;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

//
//  Category Landing Heading
//  ---------------------------------------------

.category-landing-heading {
    @include lib-font-size(20px);
    padding-bottom: $indent__s;
    border-bottom: 1px solid #e3e3e3;
    margin: 0 0 $indent__xl;

    @include max-screen($screen__l) {
        margin: $indent__l 0;
    }
    @include max-screen($screen__m) {
        margin: $indent__base 0;
    }
}
