//
//  Contact
//  ---------------------------------------------

.form.contact {
    .fieldset {
        .legend {
            display: none;
        }
        .field.note {
            display: none;
        }
    }
}
