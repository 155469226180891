//
// Actions toolbar
// --------------------------------------------------

.actions-toolbar {
    > .primary,
    > .secondary {
        @include lib-clearfix();

        .action {
            float: left;
            margin-bottom: $indent__s;

            &.action-update {
                @extend .action.primary;
                @extend .action.large;
            }
            &.action-cancel {
                @extend .action.default;
            }
            & + .action {
                margin-left: $indent__s;
            }
        }
    }
}
