//
//  Variables
//  _____________________________________________

$checkout-summary__background-color : $color-white-smoke !default;
$checkout-summary__padding          : 22px $indent__l !default;

$checkout-summary-title__margin     : $indent__s !default;
$checkout-summary-mark-value__color : $color-gray60 !default;

$checkout-summary-items__padding    : 15px !default;

//
//  Order Summary
//  ---------------------------------------------

.opc-block-summary {
    @extend .abs-add-box-sizing;
    @include lib-css(background, $checkout-summary__background-color);
    @include lib-css(padding, $checkout-summary__padding);
    margin: 0 0 $indent__base;

    > .title {
        @extend .abs-checkout-title;
        display: block;
    }

    //  Totals table
    .table-totals {
        @extend .abs-sidebar-totals;
    }

    .mark {
        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(14);
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        > .title {
            border-top: $border-width__base solid $border-color__base;
            border-bottom: $border-width__base solid $border-color__base;
            @include lib-css(padding, $indent__m $indent__xl $indent__m 0);
            cursor: pointer;
            margin-bottom: 0;
            position: relative;

            &:after {
                @extend .fas;
                @include fa-icon;
                content: fa-content($fa-var-chevron-down);
                position: absolute;
                right: 0;
                top: 17px;
            }
            strong {
                @include lib-font-size(18);
                font-weight: $font-weight__light;
                margin: 0;
            }
        }

        &.active {
            > .title {
                &:after {
                    content: fa-content($fa-var-chevron-up);
                }
            }
        }

        .product {
            position: relative;
        }
    }

    //  Cart items
    .minicart-items-wrapper {
        @include lib-css(margin, 0 -($checkout-summary-items__padding) 0 0);
        @include lib-css(padding, $checkout-summary-items__padding 0 0 0);
        border: 0;
    }

    .minicart-items {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .product-item {
        .product-image-container {
            float: left;
            width: auto !important;
            height: auto !important;
        }
        .product-item-details {
            @include lib-clearfix();
            padding-left: 88px;
        }
        .product-item-inner {
            margin: 0 0 $indent__s;
            width: 100%;
        }
        .product-item-name-block {
            margin-bottom: $indent__xs;
        }
        .product-item-name {
            display: block;
            @include lib-font-size(15);
            font-weight: normal;
        }
        .details-qty {
            float: right;

            .label {
                display: none;
            }
        }
        .price {
            @include lib-font-size(15);
            font-weight: $font-weight__regular;
        }
        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    @include lib-font-size(10);
                }
            }
        }
        .product.options {
            @include lib-font-size(13);

            .toggle {
                margin-bottom: $indent__xs;
                display: block;
                text-decoration: none;
                cursor: pointer;
                position: relative;

                &:after {
                    @extend .fas;
                    @include fa-icon;
                    content: fa-content($fa-var-chevron-down);
                    margin-left: $indent__xs;
                }
            }
            &.active {
                .toggle {
                    &:after {
                        content: fa-content($fa-var-chevron-up);
                    }
                }
            }
            .content {
                .subtitle {
                    display: none;
                }
            }
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $border-color__base;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}
