// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

@media (min-width: 0) { // Replace with appropriate media query if needed
    [data-content-type='buttons'] {
      max-width: 100%;
    }
  }
  