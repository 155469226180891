//
//  Personalisation fonts
//  _____________________________________________

@font-face {
    font-family: 'Script Mt Bold';
    src: url('/media/fonts/scriptbl.woff') format('woff'),
         url('/media/fonts/scriptbl.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OPTISport Script';
    src: url('/media/fonts/optisport-script.woff') format('woff'),
         url('/media/fonts/optisport-script.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Century Schoolbook';
    src: url('/media/fonts/censcbk.woff') format('woff'),
         url('/media/fonts/censcbk.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Adera';
    src: url('/media/fonts/aderadisplayssi.woff') format('woff'),
         url('/media/fonts/aderadisplayssi.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Century Gothic';
    src: url('/media/fonts/centurygothic.woff') format('woff'),
         url('/media/fonts/centurygothic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Copperplate Gothic Bold';
    src: url('/media/fonts/copperplategothicbold.woff') format('woff'),
         url('/media/fonts/copperplategothicbold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Olde English';
    src: url('/media/fonts/oldenglishtext.woff') format('woff'),
         url('/media/fonts/oldenglishtext.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Saloon';
    src: url('/media/fonts/saloon.woff') format('woff'),
         url('/media/fonts/saloon.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Garamond Bold';
    src: url('/media/fonts/garamondbold.woff') format('woff'),
         url('/media/fonts/garamondbold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Stencil';
    src: url('/media/fonts/stencil.woff') format('woff'),
         url('/media/fonts/stencil.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Westminster';
    src: url('/media/fonts/westminster.woff') format('woff'),
         url('/media/fonts/westminster.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Vilaya';
    src: url('/media/fonts/vilaya.woff') format('woff'),
         url('/media/fonts/vilaya.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

#product_addtocart_form {
    #product-options-wrapper .input-text { width: 100%; }
    &.Block #product-options-wrapper .input-text { font-family: Arial; font-weight: bold; }
    &.Casual #product-options-wrapper .input-text { font-family: Verdana; font-style: italic; }
    &.Script #product-options-wrapper .input-text { font-family: 'OPTISport Script'; font-weight: bold; font-size: 16px; }
    &.Schoolbook #product-options-wrapper .input-text { font-family: 'Century Schoolbook'; }
    &.Arial #product-options-wrapper .input-text { font-family: Arial; }
    &.Adera #product-options-wrapper .input-text { font-family: 'Adera'; padding-top: 4px; }
    &.Century-Gothic #product-options-wrapper .input-text { font-family: 'Century Gothic'; }
    &.Comic-Sans #product-options-wrapper .input-text { font-family: 'Comic Sans MS'; }
    &.Courier #product-options-wrapper .input-text { font-family: Courier; }
    &.Copperplate #product-options-wrapper .input-text { font-family: 'Copperplate Gothic Bold'; font-weight: bold; }
    &.Olde-English #product-options-wrapper .input-text { font-family: 'Olde English'; font-size: 16px; }
    &.Saloon #product-options-wrapper .input-text { font-family: 'Saloon'; font-size: 20px; }
    &.Schoolbook-2 #product-options-wrapper .input-text { font-family: 'Garamond Bold'; font-weight: bold; padding-top: 2px; }
    &.Stencil-Block #product-options-wrapper .input-text { font-family: 'Stencil'; padding-top: 4px; }
    &.Times-New-Roman #product-options-wrapper .input-text { font-family: 'Times New Roman'; }
    &.Westminster #product-options-wrapper .input-text { font-family: 'Westminster'; font-size: 20px; padding-top: 4px; }
    &.Vijaya #product-options-wrapper .input-text { font-family: 'Vilaya'; font-size: 20px; }
}
